import Swal from "sweetalert2";
import { call, put, takeEvery } from "redux-saga/effects";
import { RegisterActionTypes } from "./business_user_details.model";
import { register_business_details } from "./business_register_details.api";
import * as RegisterActions from "./business_user_details.action";
import { secretKey } from "../../services/config";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

export function* handleRegister(action) {
  try {
    console.log(action?.payload, "PAYLOADS");
    const response = yield call(register_business_details, action.payload);
    yield put(RegisterActions.registerBusinessSuccess(response));
    Swal.fire({
      title: action?.payload?.arabicStatus ? "تم تحديث بيانات التسجيل" : "Register Data Updated",
      text: action?.payload?.arabicStatus ? "تم تحديث بياناتك" : "Your Data Updated",
      icon: "success",
    });
  } catch (error) {
    yield put(RegisterActions.registerBusinessFailure(error));
    Swal.fire({
      title: action?.payload?.arabicStatus ? "تم تحديث بيانات التسجيل" : "Register Data Updated",
      text: action?.payload?.arabicStatus ? "لا يمكن معالجة تحديث التسجيل الخاص بك بسبب عدم اكتمال المعلومات." : "Your registration update couldn't be processed due to incomplete information.",
      icon: "error",
    });
  }
}

export function* BusinessSaga() {
  yield takeEvery(
    RegisterActionTypes.REGISTER_BUSINESS_REQUEST,
    handleRegister
  );
}
