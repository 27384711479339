export const AuthActionTypes = {
  LOGIN_USER_REQUEST: "@@business_user/LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "@@business_user/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "@@business_user/LOGIN_USER_FAILURE",
  LOGIN_BY_MOBILE_REQUEST: "@@business_user/LOGIN_BY_MOBILE_REQUEST",
  LOGIN_BY_MOBILE_SUCCESS: "@@business_user/LOGIN_BY_MOBILE_SUCCESS",
  LOGIN_BY_MOBILE_FAILURE: "@@business_user/LOGIN_BY_MOBILE_FAILURE",
  STATE_RESET_REQUEST: "@@business_user/STATE_RESET_REQUEST",
  LOGINSUCCESS_REQUEST:"@@business_user/LOGINSUCCESS_REQUEST",
};

export const authInitialState = {
  email: null,
  isLoading: false,
  signLoading: false,
  resetLoading:false,
  userLoginData: [],
  isLoginLoading:false
};

export const authSelector=(state)=>state.AuthReducer