import { call, put, takeEvery } from "redux-saga/effects";
import { BussinessActionType } from "./bussiness.model";
import * as BussinessCatActions from "./bussiness.action";
import {
  getBusCat,
  getLink,
  getLinkData,
  reviewsList,
  SendImgData,
  sendTimeData,
  postReviewList,
  postfoodMenu,
  getfoodMenu,
  postEnquiry,
  searchBussiness,
  getRelatatedBussiness,
  postFavBusiness,
  postAmentiesList,
  getAmentiesList,
  updateAmentiesApi,
  deleteAmentiesApi,
  postOfferDetailApi,
  getOfferDetailApi,
  postSpecialDetailApi,
  getSpecialDetailApi,
  postDealDetailApi,
  getDealDetailApi,
} from "./bussiness.api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const {t, i18n} = useTranslation
let arabicStatus = i18n?.language == "ar";

export function* handleBussinessCat(action) {
  try {
    const response = yield call(getBusCat, action.payload);
    console.log(response, 474674);
    yield put(BussinessCatActions.bussCatListSuccess(response));
    console.log(response, "BussinessCatResponce");
  } catch (e) {
    yield put(BussinessCatActions.bussCatListFailure(e));
  }
}

export function* handleLinks(action) {
  try {
    const response = yield call(getLink, action.payload);
    yield put(BussinessCatActions.linksScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تفاصيل الارتباط" : "Link Detail",
      text: action.payload?.arabicStatus ? "تم تحديث تفاصيل الرابط الخاص بك" :"Your Link Details Updated",
      icon: "success",
    });
  } catch (e) {
    Swal.fire({
      title: action.payload?.arabicStatus ? "خطأ في تسجيل الدخول" : "Login Error",
      text: action.payload?.arabicStatus ? "من فضلك سجل دخولك للمتابعة" : "Please SignIn to Continue",
      icon: "error",
    });
    yield put(BussinessCatActions.linksFailure(e));
  }
}

function* handleGetLink(action) {
  try {
    console.log(action.payload, "ACTIONS");
    const responce = yield call(getLinkData, action.payload);
    console.log(responce, "responcess154");
    yield put(BussinessCatActions.linkGetSuccess(responce));
  } catch (e) {
    yield put(BussinessCatActions.lilkGetFailure(e));
  }
}

function* handlegetDetailData(action) {
  try {
    console.log(action.payload, "ACTIONS");
    const responce = yield call(getLinkData, action.payload);
    console.log(responce, "responcess154");
    yield put(BussinessCatActions.detailPageDataSuccess(responce));
  } catch (e) {
    yield put(BussinessCatActions.detailPageDataFailure(e));
  }
}
function* handleSendImd(action) {
  try {
    const responce = yield call(SendImgData, action.payload);
    console.log(responce, "handleSendImd");
    yield put(BussinessCatActions.imageSendSuccess(responce));
    Swal.fire({
      title: action.payload?.arabicStatus ? "بيانات الصور" : "Images Data",
      text: action.payload?.arabicStatus ? "تم تحديث تفاصيل الصور الخاصة بك" : "Your Images Details Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.imageSendFailure(e));
    console.log(e, "errrrrr");
    Swal.fire({
      title: action.payload?.arabicStatus ? "ملف تحميل الصور" : "Image Upload File",
      text: action.payload?.arabicStatus ? "فشل تحميل ملف الصورة" : "Image Upload File Failed",
      icon: "error",
    });
  }
}
function* handleWrkTime(action) {
  try {
    const response = yield call(sendTimeData, action.payload);
    yield put(BussinessCatActions.timeSendSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "بيانات وقت العمل" : "WorkTime Data",
      text: action.payload?.arabicStatus ? "تم تحديث تفاصيل بيانات الوقت الخاصة بك" : "Your Time Data Details Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.timeSendFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "بيانات وقت العمل" : "WorkTime Data",
      text: action.payload?.arabicStatus ? "فشل بيانات وقت العمل" : "WorkTime Data Failed",
      icon: "error",
    });
  }
}

function* getReviews(action) {
  try {
    const response = yield call(reviewsList, action.payload);
    yield put(BussinessCatActions.reviewListSuccess(response));
  } catch (error) {
    yield put(BussinessCatActions.reviewListFailure(error));
  }
}

function* postReview(action) {
  try {
    const response = yield call(postReviewList, action.payload);
    yield put(BussinessCatActions.postreviewListSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تم تحديث المراجعة" : "Review Updated",
      text: action.payload?.arabicStatus ? "تم تحديث تفاصيل المراجعة الخاصة بك" : "Your Review Detail Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postreviewListFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "فشلت المراجعة" : "Review Updated",
      text: action.payload?.arabicStatus ? "فشلت مراجعة بيانات التحديث" : "Review Update Data Failed",
      icon: "error",
    });
  }
}

function* handlePostMenuList(action) {
  try {
    console.log(action.payload,88886)
    const response = yield call(postfoodMenu, action.payload);    
    yield put(BussinessCatActions.postMenuListSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تم تحديث قائمة القائمة" : "Menu List Updated",
      text: action.payload?.arabicStatus ? "تم تحديث تفاصيل القائمة الخاصة بك" : "Your Menu Detail Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postMenuListFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تحديث قائمة القائمة" : "Menu List Update",
      text: action.payload?.arabicStatus ? "فشل تحديث قائمة القائمة" : "Menu List Update Failed",
      icon: "error",
    });
  }
}

function* handleGetMenuList(action) {
  try {
    console.log(action.payload, "ACTIONS");
    const responce = yield call(getfoodMenu, action.payload);
    console.log(responce, "responcess154");
    yield put(BussinessCatActions.getMenuListSuccess(responce));
  } catch (e) {
    yield put(BussinessCatActions.getMenuListFailure(e));
  }
}

function* handleSearch(action) {
  try {
    console.log(action, "kbjkdbfdwf");
    const response = yield call(searchBussiness, action.payload);
    console.log(response, 847475);
    yield put(BussinessCatActions.searchSuccess(response));
  } catch (error) {
    yield put(BussinessCatActions.searchFailure(error));
  }
}

function* handlepostEnquiry(action) {
  try {
    const response = yield call(postEnquiry, action.payload);
    yield put(BussinessCatActions.postEnquiryListSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "بيانات الاستعلام" : "Enquiry Data",
      text: action.payload?.arabicStatus ? "تم تحديث بيانات استفسارك" : "Your Enquiry Data Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postEnquiryListFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "بيانات الاستعلام" : "Enquiry Data",
      text: action.payload?.arabicStatus ? "فشل إرسال بيانات الاستعلام" : "Enquiry Data Send Failed",
      icon: "error",
    });
  }
}
/*  For Related Bussines */
function* handlegetRelatedBusiness(action) {
  try {
    console.log(action.payload, "ACTIONS");
    const responce = yield call(getRelatatedBussiness, action.payload);
    console.log(responce, "responcess154");
    yield put(BussinessCatActions.getRelatedBussinessListSuccess(responce));
  } catch (e) {
    yield put(BussinessCatActions.getRelatedBussinessListFailure(e));
  }
}

/* Add Fav Bussiness to Card */
function* handlePostFavBusiness(action) {
  try {
    const response = yield call(postFavBusiness, action.payload);
    yield put(BussinessCatActions.postFavBussinessSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "الأعمال المفضلة" : "Favorite Business",
      text: action.payload?.arabicStatus ? "تم تحديث عملك المفضل" : "Your Favorite Bussiness Updated",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postFavBussinessFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "الأعمال المفضلة" : "Favorite Business",
      text: action.payload?.arabicStatus ? "فشل إضافة العمل المفضل" :  "Favorite Business Add Failed",
      icon: "error",
    });
  }
}

/* For Amenties Post And Get */

function* handlePostAmentis(action) {
  try {
    const response = yield call(postAmentiesList, action.payload);
    yield put(BussinessCatActions.postAmentiesScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "وسائل الراحة إضافة" : "Amenities Add",
      text: action.payload?.arabicStatus ? "تمت إضافة وسائل الراحة الخاصة بك"  : "Your Amenities Added",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postAmentiesFailure(e));
    Swal.fire({
      title:  action.payload?.arabicStatus ?  "وسائل الراحة إضافة" : "Amenities Add",
      text: action.payload?.arabicStatus ?  "فشلت إضافة وسائل الراحة" : "Amenities Added Failed",
      icon: "error",
    });
  }
}

function* handleGetAmentis(action) {
  try {
    console.log(action.payload, "ACTIONS");
    const responce = yield call(getAmentiesList, action.payload);
    console.log(responce, "responcess154");
    yield put(BussinessCatActions.getAmentiesScucess(responce));
  } catch (e) {
    yield put(BussinessCatActions.getAmentiesFailure(e));
  }
}

function* handleUpdateAmentis(action) {
  console.log(action.payload, "tyguhbjbjnk");
  try {
    const response = yield call(updateAmentiesApi, action.payload);
    yield put(BussinessCatActions.putAmentiesScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تحديث وسائل الراحة" : "Amenities Update",
      text: action.payload?.arabicStatus ? "تم تحديث وسائل الراحة الخاصة بك بنجاح" : "Your Amenities Updated Successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.putAmentiesFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ?  "تحديث وسائل الراحة" : "Amenities Update",
      text: action.payload?.arabicStatus ? "فشل تحديث وسائل الراحة" : "Amenities Update Failed",
      icon: "error",
    });
  }
}

function* handleDeleteAmentis(action) {
  try {
    const response = yield call(deleteAmentiesApi, action.payload);
    yield put(BussinessCatActions.deleteAmentiesScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "وسائل الراحة حذف" : "Amenities Delete",
      text: action.payload?.arabicStatus ? "تم حذف وسائل الراحة بنجاح" : "Amenities Deleted Successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.deleteAmentiesFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "وسائل الراحة حذف" : "Amenities Delete",      
      text: action.payload?.arabicStatus ? "فشل حذف وسائل الراحة" : "Amenities Deleted Failed",
      icon: "error",
    });
  }
}

function* handleOfferPost(action) {
  try {
    const response = yield call(postOfferDetailApi, action.payload);
    yield put(BussinessCatActions.postOfferDetailScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "إضافة تفاصيل العرض" : "Offer Detail add",
      text: action.payload?.arabicStatus ? "تمت إضافة تفاصيل العرض بنجاح" : "Offer Detail Successfully added",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postOfferDetailFailure(e));

    Swal.fire({
      title:  action.payload?.arabicStatus ? "إضافة تفاصيل العرض" : "Offer Detail add",
      text: action.payload?.arabicStatus ? "تفاصيل العرض فشلت الإضافة" : "Offer Detail Add Failed",
      icon: "error",
    });
  }
}

function* handleOfferGet(action) {
  try {
    const response = yield call(getOfferDetailApi, action.payload);
    yield put(BussinessCatActions.GetOfferDetailScucess(response));
  } catch (e) {
    yield put(BussinessCatActions.GetOfferDetailFailure(e));
  }
}

////
function* handleSpecialPost(action) {
  try {
    const response = yield call(postSpecialDetailApi, action.payload);
    yield put(BussinessCatActions.postSpecialDetailScucess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "أضيف بنجاح" : "Successfully added",
      text: action.payload?.arabicStatus ? "عرض تفاصيل النجاح" :"Offer Detail Success",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postSpecialDetailFailure(e));

    Swal.fire({
      title: action.payload?.arabicStatus ? "فشلت الإضافة" : "Added Failed",
      text: action.payload?.arabicStatus ? "حدث خطأ أثناء إضافة العنصر" : "An error occurred while adding the item",
      icon: "error",
    });
  }
}

function* handleSpecialGet(action) {
  try {
    const response = yield call(getSpecialDetailApi, action.payload);
    yield put(BussinessCatActions.GetSpecialDetailScucess(response));
  } catch (e) {
    yield put(BussinessCatActions.GetOfferDetailFailure(e));
  }
}
function* handleDealPost(action) {
  try {
    const response = yield call(postDealDetailApi, action.payload);
    yield put(BussinessCatActions.postDealDetailScucess(response));
    Swal.fire({      
      title: action.payload?.arabicStatus ? "أضيف بنجاح" : "Successfully added",
      text: action.payload?.arabicStatus ? "نجاح تفاصيل الصفقة" :"Deal Detail Success",
      icon: "success",
    });
  } catch (e) {
    yield put(BussinessCatActions.postDealDetailFailure(e));

    Swal.fire({
      title: action.payload?.arabicStatus ? "فشلت الإضافة" : "Added Failed",
      text: action.payload?.arabicStatus ? "حدث خطأ أثناء إضافة العنصر" : "An error occurred while adding the item",
      icon: "error",
    });
  }
}

function* handleDealGet(action) {
  try {
    const response = yield call(getDealDetailApi, action.payload);
    console.log(response, 49578);
    yield put(BussinessCatActions.getDealDetailScucess(response));
  } catch (e) {
    yield put(BussinessCatActions.getDealDetailFailure(e));
  }
}

export function* BussinessCatSaga() {
  yield takeEvery(
    BussinessActionType.BUSSNESSCAT_LIST_REQUEST,
    handleBussinessCat
  );
  yield takeEvery(BussinessActionType.LINKS_LIST_REQUEST, handleLinks);
  yield takeEvery(BussinessActionType.LINKSGET_LIST_REQUEST, handleGetLink);
  yield takeEvery(BussinessActionType.IMAGE_LIST_REQUEST, handleSendImd);
  yield takeEvery(BussinessActionType.WORKTYM_LIST_REQUEST, handleWrkTime);
  yield takeEvery(BussinessActionType.REVIEW_LIST_REQUEST, getReviews);
  yield takeEvery(BussinessActionType.POSTREVIEW_LIST_REQUEST, postReview);
  yield takeEvery(
    BussinessActionType.POSTMENU_LIST_REQUEST,
    handlePostMenuList
  );
  yield takeEvery(BussinessActionType.GETMENU_LIST_REQUEST, handleGetMenuList);
  yield takeEvery(
    BussinessActionType.POSTENQUIRY_LIST_REQUEST,
    handlepostEnquiry
  );
  yield takeEvery(
    BussinessActionType.GETRELDBUSSINESS_LIST_REQUEST,
    handlegetRelatedBusiness
  );
  yield takeEvery(BussinessActionType.SEARCH_REQUEST, handleSearch);
  yield takeEvery(
    BussinessActionType.POSTFAVBUSINESS_REQUEST,
    handlePostFavBusiness
  );
  yield takeEvery(BussinessActionType.POSTAMENTIES_REQUEST, handlePostAmentis);
  yield takeEvery(BussinessActionType.GETAMENTIES_REQUEST, handleGetAmentis);
  yield takeEvery(
    BussinessActionType.PUT_AMENTIES_REQUEST,
    handleUpdateAmentis
  );
  yield takeEvery(
    BussinessActionType.DELETE_AMENTIES_REQUEST,
    handleDeleteAmentis
  );
  yield takeEvery(
    BussinessActionType.DETAILPAGEDATA_REQUEST,
    handlegetDetailData
  );
  yield takeEvery(BussinessActionType.OFFERDETAILPOST_REQUEST, handleOfferPost);
  yield takeEvery(BussinessActionType.OFFERDETAILGET_REQUEST, handleOfferGet);
  yield takeEvery(
    BussinessActionType.SPECIALDEALSPOST_REQUEST,
    handleSpecialPost
  );
  yield takeEvery(
    BussinessActionType.SPECIALDEALSGET_REQUEST,
    handleSpecialGet
  );
  yield takeEvery(BussinessActionType.DEALDETAILPOST_REQUEST, handleDealPost);
  yield takeEvery(BussinessActionType.DEALDETAILGET_REQUEST, handleDealGet);
}
