export const CategoryActionTypes = {
  CATEGORY_LIST_REQUEST: "@@category/CATEGORY_LIST_REQUEST",
  CATEGORY_LIST_SUCCESS: "@@category/CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "@@category/CATEGORY_LIST_FAILURE",

  SUBCATEGORY_LIST_REQUEST: "@@subcategory/SUBCATEGORY_LIST_REQUEST",
  SUBCATEGORY_LIST_SUCCESS: "@@subcategory/SUBCATEGORY_LIST_SUCCESS",
  SUBCATEGORY_LIST_FAILURE: "@@subcategory/SUBCATEGORY_LIST_FAILURE",


};

export const categoryInitialState = {
  categories: [],
  subcaterory:[],
  isLoading: false,
  issubcLoading:false
};

export const categorySelector = (state) => state.CategoryReducer;
