export const RegisterActionTypes = {
  REGISTER_BUSINESS_REQUEST: "@@business_details/REGISTER_BUSINESS_REQUEST",
  REGISTER_BUSINESS_SUCCESS: "@@business_details/REGISTER_BUSINESS_SUCCESS",
  REGISTER_BUSINESS_FAILURE: "@@business_details/REGISTER_BUSINESS_FAILURE",
};

export const businessInitialState = {
  isBusinessRegisterLoading: false,
 regSuccessLoading:false,
};

export const RegBussinesSelector=(state)=>state.BusinessReducer
