import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { UserActionTypes } from "./user/user.model";
import { AuthReducer } from "./business_user/auth.reducer";
import { CategoryReducer } from "./category/category.reducer";
import { BusinessReducer } from "./business_user/business_user_details.reducer";
import { SettingReducer } from "./Setting/setting.reducer";
import { BussCatReducer } from "./bussiness/bussiness.reducer";
import { UserReducer } from "./user/user.reducer";
import { userDetailReducer } from "./UserDetails/userdetail.reducer";
import { AuthActionTypes } from "./business_user/auth.model";

const appReducer = combineReducers({
  AuthReducer,
  CategoryReducer,
  SettingReducer,
  BussCatReducer,
  BusinessReducer,
  UserReducer,
  userDetailReducer
});

const persistConfig = {
  key: "justcall",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  console.log(state,action,"store reducers")
  if (action.type === UserActionTypes.USER_LOGOUT) {
    state = undefined;
  }
  if(action.type===AuthActionTypes.STATE_RESET_REQUEST){
    state = undefined;
  }

  return appReducer(state, action);
  
};

export default persistReducer(persistConfig, rootReducer);
