import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faComment,
  faGripLinesVertical,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import art from "../assets/images/art.jpg";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/niceselect.css";
import "../assets/css/default.css";
import "../assets/fonts/themify-icons/themify-icons.css";
import { useEffect, useState } from "react";
import { Rating } from "@mui/material";
/* Internal Imports */
import { bussnessCatSelector } from "../store/bussiness/bussiness.model";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { API_BASE } from "../services/config";
import { settingSelector } from "../store/Setting/setting.model";
import { settingLocationRequest } from "../store/Setting/setting.action";
import app from "../assets/images/no_image.jpg";
import { postFavBussinessRequest } from "../store/bussiness/bussiness.action";

const ListView = ({ bussiness }) => {
  console.log(bussiness, 3467845756)

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuthenticated");
  const id = localStorage.getItem("id");
  let arabicStatus = i18n.language == "ar"
  const [bussinessRev, setBussinessRev] = useState(0);
  /* Selectors */
  const {
    getBussinessDetails
  } = useSelector(bussnessCatSelector);
  const { location } = useSelector(settingSelector);

  /* States */
  const [userNumber, setUserNumber] = useState(
    bussiness?.mobile ?? ""
  );
  /** Functions */
  const getCity = (cityId) => {
    return location?.find((cityData) => cityData.id == cityId)?.name;
  }
  console.log(bussiness?.user_favorite_business[0]?.business_id == bussiness?.id ,8569)
  const checkFav = (busiessId) => {
    return bussiness?.user_favorite_business?.find((favorites) => favorites?.business_id == busiessId)
  }

  /** Use Effects */
  useEffect(() => {
    dispatch(settingLocationRequest({}));
  }, [])
  useEffect(() => {
    let average = 0;
    average = bussiness?.business_user_reviews.reduce((acc, review) => {
      return (
        acc +
        parseFloat(review?.review_points) /
        bussiness?.business_user_reviews?.length
      );
    }, 0);
    average = average.toFixed(2);
    average = parseFloat(average);
    console.log(average, "sadfkl");
    setBussinessRev(average);
  }, [bussiness]);
  
  return (
    <>
      <div
        className="listing-list-wrapper cursor_pointer"

      >
        {console.log(bussiness,83475697)}
        <div className="listing-item listing-list-item-two mb-60 wow fadeInUp"  onClick={() => navigate(`/detail/${bussiness.id}`)}>
          <div className="listing-thumbnail ">
            <div className="position-ralative">
              <img
                src={
                  bussiness?.profile_imageurl != null
                    ? API_BASE + bussiness?.profile_imageurl
                    : app
                }
              />
            </div>
          </div>

          <div className="listing-content">
            <div className="details-card-padding" key={bussiness.id}  >
              <Row>               
                <Col md={6}>
                  <h3 className="title link">
                    {(i18n.language == "ar") ? bussiness.arabic_name_of_the_business : bussiness.name_of_the_business}
                  </h3>
                </Col>
                <Col md={6}>
                  <div className="link d-flex align-items-end flex-column" onClick={(event) => {                   
                    if (isAuth == "true") {
                      const sendId = {
                        business_id: parseInt(id),
                      };
                      sendId.arabicStatus = arabicStatus
                      dispatch(postFavBussinessRequest(sendId));
                    } else {
                      Swal.fire({
                        title: t('login_to'),
                        text: t('login_error_msg'),
                        icon: "error",
                      });
                    }
                    event.stopPropagation();
                  }}>
                    <i className={`ti-heart ${checkFav(bussiness?.id) ?  'wishlist-fill-icon' : 'wishlist-icon'}`} > </i>
                  </div>
                </Col>
              </Row>
              <div className="ratings">
                <ul className="ratings ratings-three">
                  <li>
                    <span className="link">
                      <a href="#" className="bold-font">
                        {bussinessRev}
                      </a>
                    </span>
                  </li>

                  <Rating value={bussinessRev} precision={0.5} readOnly />
                  <li>
                    <FontAwesomeIcon
                      className="location-info-icon"
                      icon={faGripLinesVertical}
                    />
                    <i className="ti-location-pin"></i>
                    {(i18n.language == "ar") ? bussiness.arabic_area + ', ' + bussiness.arabic_city + ', ' + bussiness.arabic_state : bussiness.area + ', ' + getCity(bussiness.city) + ', ' + bussiness.state}
                  </li>
                </ul>
              </div>
              <div className="listingcard">
                <ul>
                  {
                    (i18n.language == "ar") ?
                      bussiness?.arabic_keywords?.split("و")?.map((keys) => {
                        return <ol>{keys}</ol>;
                      })
                      :
                      bussiness?.keywords?.split(",")?.map((keys) => {
                        return <ol>{keys}</ol>;
                      })}
                </ul>
              </div>
            </div>
            <div className="button-bg">
              <Button
                className="blue-btn"
                id="ScheduleUpdateTooltip"
                onClick={(event) => {
                  if (isAuth != "true") {
                    Swal.fire({
                      title: t('login_to'),
                      text: t('login_error_msg'),
                      icon: "error",
                    });
                  }
                  event.stopPropagation();
                }}
              >
                <FontAwesomeIcon icon={faPhone} />{" "}
                {isAuth == "true" ? userNumber : t("show_number")}
              </Button>

              <Button className="voilet-btn">
                <a aria-label="Chat on WhatsApp" />
                <FontAwesomeIcon icon={faWhatsapp} /> {t("chat")}
              </Button>
              <Button className="lite-blue">
                <FontAwesomeIcon icon={faComment} /> {t("enquiry")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListView;
