import {
  BussinessActionType,
  bussinessCatInitialState,
} from "./bussiness.model";

const reducer = (state = bussinessCatInitialState, action) => {
  console.log(action.payload, "const1");

  switch (action.type) {
    case BussinessActionType.BUSSNESSCAT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case BussinessActionType.BUSSNESSCAT_LIST_SUCCESS:
      console.log(action, "bussder");
      return {
        ...state,
        isLoading: false,
        busscat: action.payload.data.datas,
      };
    case BussinessActionType.BUSSNESSCAT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case BussinessActionType.LINKS_LIST_REQUEST:
      return {
        ...state,
        isLinkLoading: true,
      };

    case BussinessActionType.LINKS_LIST_SUCCESS:
      return {
        ...state,
        isLinkLoading: false,
      };
    case BussinessActionType.LINKS_LIST_FAILURE:
      return {
        ...state,
        isLinkLoading: false,
      };
    case BussinessActionType.LINKSGET_LIST_REQUEST:
      return {
        ...state,
        isgetLinkLoading: true,
      };

    case BussinessActionType.LINKSGET_LIST_SUCCESS:
      return {
        ...state,
        isgetLinkLoading: false,
        getBussinessDetails: action?.payload?.data?.businessUserDetails,
      };
    case BussinessActionType.LINKSGET_LIST_FAILURE:
      return {
        ...state,
        isgetLinkLoading: false,
      };
    case BussinessActionType.DETAILPAGEDATA_REQUEST:
      return {
        ...state,
        isgetLinkLoading: true,
      };

    case BussinessActionType.DETAILPAGEDATA_SUCCESS:
      return {
        ...state,
        isgetLinkLoading: false,
        detailPageData: action?.payload?.data?.businessUserDetails,
      };
    case BussinessActionType.DETAILPAGEDATA_FAILURE:
      return {
        ...state,
        isgetLinkLoading: false,
      };
    case BussinessActionType.IMAGE_LIST_REQUEST:
      return {
        ...state,
        isImgLoading: true,
      };
    case BussinessActionType.IMAGE_LIST_SUCCESS:
      return {
        ...state,
        isImgLoading: false,
      };
    case BussinessActionType.IMAGE_LIST_FAILURE:
      return {
        ...state,
        isImgLoading: false,
      };
    case BussinessActionType.WORKTYM_LIST_REQUEST:
      return {
        ...state,
        isWortTimeLoading: true,
      };
    case BussinessActionType.WORKTYM_LIST_SUCCESS:
      return {
        ...state,
        isWortTimeLoading: false,
      };
    case BussinessActionType.WORKTYM_LIST_FAILURE:
      return {
        ...state,
        isWortTimeLoading: false,
      };

    case BussinessActionType.REVIEW_LIST_REQUEST:
      return {
        ...state,
        isReviewLoading: true,
      };

    case BussinessActionType.REVIEW_LIST_SUCCESS:
      var { payload } = action;
      console.log(payload, "reviewData ");
      return {
        ...state,
        isReviewLoading: false,
        reviewData: payload?.data?.data,
        reviewCount: payload?.data?.count,
      };
    case BussinessActionType.REVIEW_LIST_FAILURE:
      return {
        ...state,
        isReviewLoading: false,
      };

    case BussinessActionType.POSTREVIEW_LIST_REQUEST:
      return {
        ...state,
        isPostReveiwLoading: true,
        postSuccess: false,
      };

    case BussinessActionType.POSTREVIEW_LIST_SUCCESS:
      return {
        ...state,
        isPostReveiwLoading: false,
        postSuccess: false,
      };
    case BussinessActionType.POSTREVIEW_LIST_FAILURE:
      return {
        ...state,
        isPostReveiwLoading: false,
        postSuccess: false,
      };

    case BussinessActionType.POSTMENU_LIST_REQUEST:
      return {
        ...state,
        isMenuLoading: true,
      };

    case BussinessActionType.POSTMENU_LIST_SUCCESS:
      return {
        ...state,
        isMenuLoading: false,
      };
    case BussinessActionType.POSTMENU_LIST_FAILURE:
      return {
        ...state,
        isMenuLoading: false,
      };

    case BussinessActionType.GETMENU_LIST_REQUEST:
      return {
        ...state,
        isgetMenuLoading: true,
      };
    case BussinessActionType.GETMENU_LIST_SUCCESS:
      console.log(action.payload, "dsfdsfsf12");
      return {
        ...state,
        isgetMenuLoading: false,
        getMenuData: action?.payload?.data?.foodItems,
      };
    case BussinessActionType.GETMENU_LIST_FAILURE:
      return {
        ...state,
        isgetMenuLoading: false,
      };

    case BussinessActionType.POSTENQUIRY_LIST_REQUEST:
      return {
        ...state,
        isenquiryLoading: true,
      };
    case BussinessActionType.POSTENQUIRY_LIST_SUCCESS:
      return {
        ...state,
        isenquiryLoading: false,
      };
    case BussinessActionType.POSTENQUIRY_LIST_FAILURE:
      return {
        ...state,
        isenquiryLoading: false,
      };

    case BussinessActionType.GETRELDBUSSINESS_LIST_REQUEST:
      return {
        ...state,
        isgetRelBusinessLoading: true,
      };
    case BussinessActionType.GETRELDBUSSINESS_LIST_SUCCESS:
      return {
        ...state,
        isgetRelBusinessLoading: false,
        getRelatedBussiness: action?.payload?.data,
      };
    case BussinessActionType.GETRELDBUSSINESS_LIST_FAILURE:
      return {
        ...state,
        isgetRelBusinessLoading: false,
      };
    case BussinessActionType.POSTFAVBUSINESS_REQUEST:
      return {
        ...state,
        isFavBusLoading: true,
      };
    case BussinessActionType.POSTFAVBUSINESS_SUCCESS:
      return {
        ...state,
        isFavBusLoading: false,
      };
    case BussinessActionType.POSTFAVBUSINESS_FAILURE:
      return {
        ...state,
        isFavBusLoading: false,
      };

    case BussinessActionType.POSTAMENTIES_REQUEST:
      return {
        ...state,
        postamentiesLoading: true,
        amentisSuccess: false,
      };
    case BussinessActionType.POSTAMENTIES_SUCCESS:
      return {
        ...state,
        postamentiesLoading: false,
        amentisSuccess: true,
      };
    case BussinessActionType.POSTAMENTIES_FAILURE:
      return {
        ...state,
        postamentiesLoading: false,
        amentisSuccess: false,
      };

    case BussinessActionType.GETAMENTIES_REQUEST:
      return {
        ...state,
        getAmentiesLoading: true,
      };
    case BussinessActionType.GETAMENTIES_SUCCESS:
      return {
        ...state,
        getAmentiesLoading: false,
        getAmentiesData: action?.payload?.data?.amenitiesData,
      };
    case BussinessActionType.GETAMENTIES_FAILURE:
      return {
        ...state,
        getAmentiesLoading: false,
      };

    case BussinessActionType.SEARCH_REQUEST:
      return {
        ...state,
        getFilterLoading: true,
      };
    case BussinessActionType.SEARCH_SUCCESS:
      console.log(action.payload, 48464);
      return {
        ...state,
        getFilterLoading: false,
        getFilterBusinessData: action?.payload?.data?.datas?.data,
      };
    case BussinessActionType.SEARCH_FAILURE:
      return {
        ...state,
        getFilterLoading: false,
      };
    case BussinessActionType.RESET_REQUEST:
      return {
        ...state,
        busscat: [],
        getFilterBusinessData: [],
        getFilterLoading: false,
        isLoading: false,
      };
    case BussinessActionType.PUT_AMENTIES_REQUEST:
      return {
        ...state,
        updateAmentisLoading: true,
      };
    case BussinessActionType.PUT_AMENTIES_SUCCESS:
      return {
        ...state,
        updateAmentisLoading: false,
      };
    case BussinessActionType.PUT_AMENTIES_FAILURE:
      return {
        ...state,
        updateAmentisLoading: false,
      };
    case BussinessActionType.DELETE_AMENTIES_REQUEST:
      return {
        ...state,
        deleteAmentiesLoading: true,
      };
    case BussinessActionType.DELETE_AMENTIES_SUCCESS:
      return {
        ...state,
        deleteAmentiesLoading: false,
      };
    case BussinessActionType.DELETE_AMENTIES_FAILURE:
      return {
        ...state,
        deleteAmentiesLoading: false,
      };

    case BussinessActionType.OFFERDETAILPOST_REQUEST:
      return {
        ...state,
        offerPostLoading: true,
        successOffer: false,
      };
    case BussinessActionType.OFFERDETAILPOST_SUCCESS:
      return {
        ...state,
        offerPostLoading: false,
        successOffer: true,
      };
    case BussinessActionType.OFFERDETAILPOST_FAILURE:
      return {
        ...state,
        offerPostLoading: false,
        successOffer: false,
      };

    case BussinessActionType.OFFERDETAILGET_REQUEST:
      return {
        ...state,
        offerPostLoading: true,
      };
    case BussinessActionType.OFFERDETAILGET_SUCCESS:
      return {
        ...state,
        offerPostLoading: false,
      };
    case BussinessActionType.OFFERDETAILGET_FAILURE:
      return {
        ...state,
        offerPostLoading: false,
      };

    ////
    case BussinessActionType.SPECIALDEALSPOST_REQUEST:
      return {
        ...state,
        dealspostLoading: true,
      };
    case BussinessActionType.SPECIALDEALSPOST_SUCCESS:
      return {
        ...state,
        dealspostLoading: false,
      };
    case BussinessActionType.SPECIALDEALSPOST_FAILURE:
      return {
        ...state,
        dealspostLoading: false,
      };

    case BussinessActionType.SPECIALDEALSGET_REQUEST:
      return {
        ...state,
        dealsgetLoading: true,
        successDeals: false,
      };
    case BussinessActionType.SPECIALDEALSGET_SUCCESS:
      return {
        ...state,
        dealsgetLoading: false,
        successDeals: true,
      };
    case BussinessActionType.SPECIALDEALSGET_FAILURE:
      return {
        ...state,
        dealsgetLoading: false,
        successDeals: false,
      };
    case BussinessActionType.DEALDETAILPOST_REQUEST:
      return {
        ...state,
        dealPostLoading: true,
        successDeal: false,
      };
    case BussinessActionType.DEALDETAILPOST_SUCCESS:
      return {
        ...state,
        dealPostLoading: false,
        successDeal: true,
      };
    case BussinessActionType.DEALDETAILPOST_FAILURE:
      return {
        ...state,
        dealPostLoading: false,
        successDeal: false,
      };
    case BussinessActionType.DEALDETAILGET_REQUEST:
      return {
        ...state,
        dealGetLoading: true,
      };
    case BussinessActionType.DEALDETAILGET_SUCCESS:
      console.log(action.payload, 48464);
      return {
        ...state,
        dealGetLoading: false,
        getDealData: action?.payload?.data?.dealDetails,
      };
    case BussinessActionType.DEALDETAILGET_FAILURE:
      return {
        ...state,
        dealGetLoading: false,
      };
    default:
      return state;
  }
};

export { reducer as BussCatReducer };
