import Home from "../components/Home";
import Listing from "../components/Listing";
import BusinessLogin from "../components/business_user/RegisterBusiness";
import Signup from "../components/Signup";
import RegisterBusiness from "../components/business_user/RegisterBusiness";
import BusinessDetails from "../components/BusinessDetails";
import Details from "../components/business_user/Details";
import UserDetail from "../components/UserDetail/UserDetail";

const authProtectedRoutes = [
  { path: "/register_business", component: RegisterBusiness },
  { path: "/business_details/:id", component: BusinessDetails },
  { path: "/userdetail/:id", component: UserDetail },
];

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/business_login", component: BusinessLogin },
  { path: "/signup", component: Signup },
  { path: "/list/:category_id", component: Listing },
  { path: "/list", component: Listing },
  { path: "/detail/:id", component: Details }  
];

export { authProtectedRoutes, publicRoutes };
