/** External Imports */
import { Col, Container, Row, Form, Input } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { imageSendRequest } from "../../store/bussiness/bussiness.action";
import { useTranslation } from "react-i18next";

/** Internal Imports */

function ImageUpload({ onNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {i18n, t} = useTranslation();
  let arabicStatus = i18n.language == "ar"
  /* Selectors */

  /* States */
  const [files, setFiles] = useState(null);
console.log(files, "tyghbnj")
  /* Function */
  const handleUploadClick = () => {
    if (files?.length) {
      files.arabicStatus = arabicStatus
      dispatch(imageSendRequest(files));
    }
  };

  const handleBackgroundImageUpload = (event) => {
    
    setFiles(event.target.files)
    // setFiles(URL.createObjectURL(file));
  };
  const handleClickNext = () => {
    onNext();
  };
  /* FormData */

  /* UseEffect */
  return (
    <div>
      <div className="add-listing-form form-space  mb-60 wow fadeInUp">
        <h4 className={`${i18n.language == "ar" ? "arabic_title" : "title"}`}>{t("gallery") + " " + t("images")}</h4>

        <div className="filled form-group tooltip-end-top">
          <label className="label-style">{t("upload")+ " " + t("images")}:</label>
          <Row>
            <Col lg={6}>
              <Form.Control
                className="border-business"
                type="file"
                accept="image/*"
                name="image_url"
                multiple
                onChange={handleBackgroundImageUpload}

              />
            </Col>
            <div className="slidingClass" id="sliderContainer">
        {/*   <div className="slideClass">
            {
              files ?(
                files&& 
              <img src={files}></img>
            ):(<div></div>)
            }
            </div> */}
        </div>
           {files == null &&
           <p className="text-danger">{t("image_error")}</p>
           }
          </Row>
          <Col lg={6}>
            <button onClick={handleUploadClick} className="main-btn mt-20">{t("upload")}</button>
            </Col>
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
