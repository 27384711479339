import { CategoryActionTypes, categoryInitialState } from "./category.model";

const reducer = (state = categoryInitialState, action) => {
  switch (action.type) {
    case CategoryActionTypes.CATEGORY_LIST_REQUEST:
    case CategoryActionTypes.CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isLoading: true,
      };
    case CategoryActionTypes.CATEGORY_LIST_SUCCESS:
      var { payload } = action;      
      return {
        ...state,
        isLoading: false,
        categories: payload?.data?.datas,
      };
      case CategoryActionTypes.SUBCATEGORY_LIST_REQUEST:
      case CategoryActionTypes.SUBCATEGORY_LIST_FAILURE:
        return{
          ...state,
          issubcLoading:true
        }
      case CategoryActionTypes.SUBCATEGORY_LIST_SUCCESS:
        var {payload}=action
        return{
          ...state,
          issubcLoading:false,
          subcaterory:payload?.data?.datas
        }

    default:
      return state;
  }
};

export { reducer as CategoryReducer };
