import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import {
  postOfferDetailRequest,
  postSpecialDetailRequest,
  postDealDetailRequest,
} from "../../store/bussiness/bussiness.action";
import {
  businessOfferValidator,
  businessDealValidator,
} from "../../validators/Validators";

const OfferDetail = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let arabicStatus = i18n.language == "ar"
  /** States */
  const [langSwitch, setLangSwitch] = useState(1);
  const [offerImage, setOfferImage] = useState();
  const [endDate, setEndDate] = useState();
  const [offerErrorMsg, setOfferErrorMsg] = useState({
    date: false,
    img: false,
  });
  /** For Deal */
  const [dealImage, setDealImage] = useState();
  const [dealEndDate, setDealEndDate] = useState();
  const [dealErrorMsg, setDealErrorMsg] = useState({
    date: false,
    img: false,
  });
  /** Initial Values */
  const initialValues = {
    offer: "",
    offer_desc: "",
  };

  const onSubmit = (formData) => {
    if (offerImage && endDate) {
      formData.offer_img = offerImage;
      formData.offer_end_date = endDate;
      formData.language_type = langSwitch;
      console.log(formData, 3937);
      
      if (endDate && offerImage) {
        formData.arabicStatus = arabicStatus
        dispatch(postOfferDetailRequest(formData));

      resetForm();
      setEndDate();
      setOfferImage();
      }
    }
  };

  /** Form Data */
  const formik = useFormik({
    initialValues,
    validationSchema: businessOfferValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } =
    formik;

  /** Deal Form */
  const dealValues = {
    deal: "",
    deal_desc: "",
  };

  const onDealSubmit = (formData) => {
    formData.deal_img = dealImage;
    formData.deal_end_date = dealEndDate;
    formData.language_type = langSwitch;
    console.log(formData, 4847);
    if (dealEndDate && dealImage) {
      formData.arabicStatus = arabicStatus
      dispatch(postDealDetailRequest(formData));
      dealsetValues({
        deal: "",
        deal_desc: ""
      })
      dealFormReset();
      setDealEndDate();
      setDealImage();
    }
  };

  const formik2 = useFormik({
    initialValues: dealValues,
    validationSchema: businessDealValidator,
    onSubmit: onDealSubmit,
  });

  const {
    handleSubmit: handleDealSubmit,
    handleChange: handleDeal,
    values: newValues,
    touched: newtouched,
    errors: newerrors,
    resetForm: dealFormReset,
    setValues: dealsetValues,
  } = formik2;

  console.log(newValues, 3453453)
  return (
    <div>
      <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp ">
        <Row>
          <Col md={6}>
            {" "}
            <h4 className="title">{t("offer") + " & " + t("deal") + " " + t("details")}</h4>
          </Col>
          <Col md={6}>
            <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
              {langSwitch == 1 ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setLangSwitch(2)}
                >
                  اﻟﻌﺮﺑﻴﺔ
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setLangSwitch(1)}
                >
                  English
                </button>
              )}
            </div>
          </Col>
        </Row>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <h4 className="title">{t("offer") + " " + t("details")}</h4>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="input1">
                <Form.Label className="label-style">{t("offer") + " " + t("name")} :</Form.Label>
                <div class="form_group">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("offer") + " " + t("name")}
                    name="offer"
                    value={values.offer}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{arabicStatus ? errors.offer?.ar : errors.offer?.en}</p>
                </div>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <div class="form_group">
                <label className="label-style">{t("offer") + " " + t("end") + " " + t("date")} :</label>
                <DatePicker
                  className="date"
                  name="offer_end_date"
                  placeholderText={t("offer") + " " + t("end") + " " + t("date")}
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setOfferErrorMsg({
                      date: false,
                    });
                  }}
                  minDate={new Date()}
                />
                {offerErrorMsg?.date && (
                  <p className="text-danger">{t("offer") + " " + t("end") + " " + t("date") + " " + t("is") + " " + t("required")}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="filled dn-space form-group tooltip-end-top">
                <label className="label-style">{t("offer") + " " + t("description")} :</label>
                <Form.Control
                  className="border-business"
                  as="textarea"
                  name="offer_desc"
                  placeholder={t("offer") + " " + t("description")}
                  value={values.offer_desc}
                  onChange={handleChange}
                />
                <p className="text-danger">{arabicStatus ? errors.offer_desc?.ar : errors.offer_desc?.en}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div class="form_group">
                <label className="label-style">{t("offer") + " " + t("image")} :</label>
                <Form.Control
                  className="border-business"
                  type="file"
                  accept="image/*"
                  name="offer_img"
                  onChange={(e) => {
                    setOfferImage(e.target.files[0]);
                    setOfferErrorMsg({
                      img: false,
                    });
                  }}
                  placeholderText=""
                />
                {offerErrorMsg?.img && (
                  <p className="text-danger">{t("offer") + " " + t("image") + " " + t("is") + " " + t("required")} </p>
                )}
              </div>
            </Col>
          </Row>

          <Button
            className="main-btn mt-2"
            type="submit"
            onClick={() => {
              if (!endDate || !offerImage) {
                setOfferErrorMsg({
                  date: !endDate,
                  img: !offerImage,
                });
              } else {
                setOfferErrorMsg({
                  date: !endDate,
                  img: !offerImage,
                });
              }
            }}
          >
            {t("submit")}
          </Button>
          <hr />
        </Form>
        {/* Deal Form */}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleDealSubmit();
          }}
        >
          <h4 className="title">{t("deal") + " " + t("details")}</h4>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="input1">
                <Form.Label className="label-style">
                  {t("last") + " " + t("minute") + " " + t("deal") + t("name")}:
                </Form.Label>
                <div class="form_group">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("deal") + t("name")}
                    name="deal"
                    value={newValues.deal}
                    onChange={handleDeal}
                  />
                  <p className="text-danger">{arabicStatus ? newerrors?.deal?.ar : newerrors?.deal?.en}</p>
                </div>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <div class="form_group">
                <label className="label-style">{t("deal") + " " + t("end") + " " + t("date")}:</label>
                <DatePicker
                  className="date"
                  name="deal_end_date"
                  placeholderText={t("deal") + " " + t("end") + " " + t("date")}
                  selected={dealEndDate}
                  onChange={(date) => {
                    setDealEndDate(date);
                    setDealErrorMsg({
                      date: false,
                    });
                  }}
                  minDate={new Date()}
                />
                {dealErrorMsg?.date && (
                  <p className="text-danger">{t("deal") + " " + t("end") + " " + t("date") + " " + t("is") + " " + t("required")}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="filled dn-space form-group tooltip-end-top">
                <label className="label-style">{t("deal") + " " + t("description")}:</label>
                <Form.Control
                  className="border-business"
                  as="textarea"
                  name="deal_desc"
                  placeholder={t("deal") + " " + t("description")}
                  value={newValues.deal_desc}
                  onChange={handleDeal}
                />
                <p className="text-danger">{arabicStatus ? newerrors?.deal_desc?.ar : newerrors?.deal_desc?.en}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div class="form_group">
                <label className="label-style">{t("deal") + " " + t("image")}:</label>
                <Form.Control
                  className="border-business"
                  type="file"
                  accept="image/*"
                  name="deal_img"
                  onChange={(e) => {
                    setDealImage(e.target.files[0]);
                    setDealErrorMsg({
                      img: false,
                    });
                  }}
                  placeholderText=""
                />
                {console.log(dealErrorMsg, 349573475)}
                {dealErrorMsg?.img && (
                  <p className="text-danger">{t("deal") + " " + t("image") + " " + t("is") + " " + t("required")}</p>
                )}
              </div>
            </Col>
          </Row>

          <Button
            className="main-btn mt-2"
            type="submit"
            onClick={() => {
              if (!dealEndDate || !dealImage) {
                setDealErrorMsg({
                  date: !dealEndDate,
                  img: !dealImage,
                });
              } else {
                setDealErrorMsg({
                  date: !dealEndDate,
                  img: !dealImage,
                });
              }
            }}
          >
            {t("submit")}
          </Button>
          <hr />
        </Form>
      </div>
    </div>
  );
};

export default OfferDetail;
