import { all, call, put, takeEvery } from "redux-saga/effects";
import * as UserActions from "./user.action";
import { UserActionTypes } from "./user.model";
import Swal from "sweetalert2";
import { userLogin, userRegister } from "./user.api";

export function* handleUserRegister(action) {
  try {    
    const response = yield call(userRegister, action.payload);   
    yield put(UserActions.userRegisterSuccess(response));
    Swal.fire({
      title: action.payload?.arabicStatus ? "إرسال OTP" : "OTP Send",
      text: action.payload?.arabicStatus ? "تم إرسال OTP إلى معرف البريد المحدد بنجاح" : "OTP send to given Mail Id successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(UserActions.userRegisterFailure(e));
    Swal.fire({
      title: action.payload?.arabicStatus ? "إرسال OTP" : "OTP Send",
      text: action.payload?.arabicStatus ?" فشل إرسال OTP إلى معرف البريد المحدد" : "OTP send to given Mail Id failed",
      icon: "success",
    });
  }
}

export function* handleUserLogin(action) {
  try {   
    const response = yield call(userLogin, action.payload);
    yield put(UserActions.userLoginSuccess(response));
  } catch (error) {
    yield put(UserActions.userLoginFailure(error));
    Swal.fire({
      title: action.payload?.arabicStatus ? "تسجيل الدخول" : "Login!",
      text:  action.payload?.arabicStatus ? "تم رفض تسجيل الدخول!" : "Login Denied!",
      icon: "error",
    });
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.REGISTER_USER_REQUEST, handleUserRegister);
  yield takeEvery(UserActionTypes.LOGIN_USER_REQUEST, handleUserLogin);
}
