import {
  RegisterActionTypes,
  businessInitialState,
} from "./business_user_details.model";

const reducer = (state = businessInitialState, action) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_BUSINESS_REQUEST:
      return {
        ...state,
        isBusinessRegisterLoading: true,
        regSuccessLoading:false,
      };

    case RegisterActionTypes.REGISTER_BUSINESS_SUCCESS:      
      return {
        ...state,
        isBusinessRegisterLoading: false,
        regSuccessLoading:true,
        };
      
    case RegisterActionTypes.REGISTER_BUSINESS_FAILURE:
      return {
        ...state,
        isBusinessRegisterLoading: false,
        regSuccessLoading:false,
      };
    default:
      return state;
  }
};

export { reducer as BusinessReducer };
