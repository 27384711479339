import { AuthActionTypes, authInitialState } from "./auth.model";

const reducer = (state = authInitialState, action) => {
  console.log(action, "TESATEF");
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoginLoading:false,

      };

    case AuthActionTypes.LOGIN_USER_SUCCESS:
      const { payload } = action;
      localStorage.setItem("authToken", payload?.data?.datas?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("email", payload?.data?.datas?.email);
      localStorage.setItem("role", action?.payload?.data?.datas?.role);
      localStorage.setItem("id", action?.payload?.data?.datas?.id);
      return {
        ...state,
        isLoading: false,
        isLoginLoading:true,
        userLoginData: payload?.data?.datas?.userData,
      };

    case AuthActionTypes.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoginLoading:false,

      };
    case AuthActionTypes.LOGIN_BY_MOBILE_REQUEST:
      return {
        ...state,
        signLoading: true,
      };

    case AuthActionTypes.LOGIN_BY_MOBILE_SUCCESS:
      return {
        ...state,
        signLoading: false,
        email: action.payload?.data?.email,
      };

    case AuthActionTypes.LOGIN_BY_MOBILE_FAILURE:
      return {
        ...state,
        signLoading: false,
      };
    case AuthActionTypes.STATE_RESET_REQUEST:
      return {
          ...state,
          resetLoading: true,
        email: null,
        userLoginData: [],
      };

      case AuthActionTypes.LOGINSUCCESS_REQUEST:
        console.log("iamgoinf")
        return{
          ...state,
          isLoginLoading:false
        }
    default:
      return state;
  }
};
export { reducer as AuthReducer };
