import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const getCategory = () => {  
  const baseUrl = getBaseEndpointUrl();  
  console.log(baseUrl, "baseurl")
  return Http.get(baseUrl + "/category/list");
};

export const getSubCategory =()=>{
  const baseUrl=getBaseEndpointUrl();  
  return Http.get(baseUrl+"/sub_category/list")
}