import * as Yup from "yup";

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const businesssigninValidator = Yup.object().shape({
  email: Yup.string().email().required({en:"Email is Required",ar:"البريد الالكتروني مطلوب"}),
  otp: Yup.string().required({en:"OTP is Required",ar:"مطلوب مكتب المدعي العام"}),
});

export const businesssotpValidator = Yup.object().shape({
  email: Yup.string().required({en:"Email is Required",ar:"البريد الالكتروني مطلوب"}),
});

/** Business Register Validator */
export const businessRegisterValidator = Yup.object().shape({
  name: Yup.string().required({en:"User Name is Required",ar:"اسم المستخدم مطلوب"}),
  name_of_the_business: Yup.string().required({en:"Business Name is Required", ar: "الاسم التجاري مطلوب"}),
  mobile: Yup.string().required({en:"Mobile is Required", ar: "الجوال مطلوب"}),
  door_no: Yup.string().required({en:"Door No is Required", ar: "رقم الباب مطلوب"}),
  street : Yup.string().required({en:"State No is Required",ar:"الدولة رقم مطلوب"}),
  area: Yup.string().required({en:"Area is Required", ar:"المنطقة مطلوبة"}),  
  state: Yup.string().required({en:"State is Required", ar: "الدولة مطلوبة"}),
  pin_code: Yup.number().required({en:"Pin Code is Required", ar: "مطلوب رمز التعريف الشخصي"}),
  about_business: Yup.string().nullable(true),
});

//bussiness details validator
export const linkDetailValidator = Yup.object().shape({
  company_link: Yup.string().nullable(true),
  facebook_link: Yup.string().nullable(true),
  twitter_link: Yup.string().nullable(true),
  linked_in_link: Yup.string().nullable(true),
  skype_link: Yup.string().nullable(true),
});

//UserDetails Validator

export const UserDetailValidator = Yup.object().shape({
  name: Yup.string().nullable(true),
  mobile: Yup.string().required({en:"Mobile No Required",ar:"الجوال غير مطلوب"}),
  pin_code: Yup.number().required({en:"Pin code Is Required",ar:"الرقم السري مطلوب"}),
  address: Yup.string().nullable(true),
});

/* Review Validator */
export const ReviewDetailValidator = Yup.object().shape({
  user_name: Yup.string().required({en:"Enter Your Name",ar:"أدخل أسمك"}),
  review_comment: Yup.string().required({en:"Write a Comment",ar:"أكتب تعليقا"}),
  user_mail: Yup.string()
    .email({en:"Must valid Email",ar:"يجب أن يكون البريد الإلكتروني صالحًا"})
    .required({en:"Mail Id Required", ar:"معرف البريد مطلوب"}),
});

/* Menu Validator */
export const MenuDetailValidator = Yup.object().shape({
  main_item: Yup.string().required("Selct Main Item"),
  food_name: Yup.string().required("Write Food Name"),
  price: Yup.number().required("Enter Price"),
  food_type: Yup.string().required("Select Food Type"),
});

/* Enquiry Validator */

export const EnquiryValidator = Yup.object().shape({
  name: Yup.string().required({en:"Enter Your Name",ar:"أدخل أسمك"}),
  phone: Yup.number().required({en:"Enter Your Mobile No", ar:"أدخل رقم هاتفك المحمول"}),
  enquiry: Yup.string().required({en:"Write Your Message",ar:"اكتب رسالتك"}),
});

/* Amenties Validator */
export const AmentiesValidator = Yup.object().shape({
  amenities_name: Yup.string().required("Enter Amenties Name"),
});

/** Business Offer Validator */
export const businessOfferValidator = Yup.object().shape({
  offer: Yup.string().required({en:"Offer Name is Required",ar:"اسم العرض مطلوب"}),
  offer_desc: Yup.string().required({en:"Offer Description is Required", ar: "وصف العرض مطلوب"}),
});
export const businessDealValidator = Yup.object().shape({
  deal: Yup.string().required({en:"Deal Name is Required", ar: "اسم الصفقة مطلوب"}),
  deal_desc: Yup.string().required({en:"Deal Description is Required", ar: "وصف الصفقة مطلوب"}),
});

