export const UserActionTypes = {
  REGISTER_USER_REQUEST: "@@user/REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "@@user/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "@@user/REGISTER_USER_FAILURE",

  LOGIN_USER_REQUEST: "@@user/LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "@@user/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "@@user/LOGIN_USER_FAILURE",

  USER_LOGOUT: "@@user/USER_LOGOUT",
  USERDETAIL_RESET: "@@userdetail/USERDETAIL_RESET",
  LOGINSUCCESS_RESET: "@@user/LOGINSUCCESS_RESET",
};

export const userInitialState = {
  user_email: null,
  user: [],
  isUserRegisterLoading: false,
  isUserLoginLoading: false,
  isUserDetailLoading: false,
  loginSuccess: false,
};

export const userSelector = (state) => state.UserReducer;
