export const LocationActionTypes = {
    LOCATION_LIST_REQUEST: "@@location/LOCATION_LIST_REQUEST",
    LOCATION_LIST_SUCCESS: "@@location/LOCATION_LIST_SUCCESS",
    LOCATION_LIST_FAILURE: "@@location/LOCATION_LIST_FAILURE",
  };
  
  export const CountryActionTypes = {
    COUNTRY_LIST_REQUEST: "@@country/COUNTRY_LIST_REQUEST",
    COUNTRY_LIST_SUCCESS: "@@country/COUNTRY_LIST_SUCCESS",
    COUNTRY_LIST_FAILURE: "@@country/COUNTRY_LIST_FAILURE",
  };
  
  export const PlaceActionTypes = {
    PLACE_LIST_REQUEST: "@@place/PLACE_LIST_REQUEST",
    PLACE_LIST_SUCCESS: "@@place/PLACE_LIST_SUCCESS",
    PLACE_LIST_FAILURE: "@@place/PLACE_LIST_FAILURE",
  };
  
    
  export const subLocationActionTypes = {
    SUB_LOCATION_LIST_REQUEST: "@@location/SUB_LOCATION_LIST_REQUEST",
    SUB_LOCATION_LIST_SUCCESS: "@@location/SUB_LOCATION_LIST_SUCCESS",
    SUB_LOCATION_LIST_FAILURE: "@@location/SUB_LOCATION_LIST_FAILURE",        
  };
  
  export const settingInitialState = {
    location: [],
    country:[],
    place:[],
    sub_location: [],
    locationLoading:false,
    countryLoading:false,
    isLoading: false,
    sub_locationLoading:false,
  };

  export const settingSelector = (state) => state.SettingReducer; 