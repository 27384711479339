import { action } from "typesafe-actions";
import { UserDetailTypes } from "./userdetail.model";

export const postuserDetailsRequest = (req) =>
  action(UserDetailTypes.USERDETAIL_SEND_REQUEST, req);
export const postuserDetailsSuccess = (res) =>
  action(UserDetailTypes.USERDETAIL_SEND_SUCCESS, res);
export const postuserDetailsFailure = (err) =>
  action(UserDetailTypes.USERDETAIL_SEND_FAILURE, err);

export const getUserDetailRequest = (req) =>
  action(UserDetailTypes.GETUSERDETAIL_RECIEVE_REQUEST, req);
export const getUserDetailSuccess = (res) =>
  action(UserDetailTypes.GETUSERDETAIL_RECIEVE_SUCCESS, res);
export const getUserDetailFailure = (err) =>
  action(UserDetailTypes.GETUSERDETAIL_RECIEVE_FAILURE, err);

export const verifyuserMobileRequest = (req) =>
  action(UserDetailTypes.USER_VERIFYMOBILE_REQUEST, req);
export const verifyuserMobileSuccess = (res) =>
  action(UserDetailTypes.USER_VERIFYMOBILE_SUCCESS, res);
export const verifyuserMobileFailure = (err) =>
  action(UserDetailTypes.USER_VERIFYMOBILE_FAILURE, err);
  
export const verifyuserMobileOTPRequest = (req) =>
  action(UserDetailTypes.USER_VERIFYMOBILEOTP_REQUEST, req);
export const verifyuserMobileOTPSuccess = (res) =>
  action(UserDetailTypes.USER_VERIFYMOBILEOTP_SUCCESS, res);
export const verifyuserMobileOTPFailure = (err) =>
  action(UserDetailTypes.USER_VERIFYMOBILEOTP_FAILURE, err);

export const resetStatesRequest = (req) =>
  action(UserDetailTypes.RESETSTATE_SEND_REQUEST, req);
