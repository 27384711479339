import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const getBusCat = (query) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "bussinescategory");
  return Http.get(baseUrl + "/business_user/list/?query=" + query);
};
export const getLink = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl);
  return Http.post(
    baseUrl + "/business_user/link_details/",
    formData,
    Http.getAuthToken()
  );
};

export const getLinkData = (query) => {
  console.log(query, "query");
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "getBaseUrl");
  return Http.get(
    baseUrl + "/business_user/by_id/?query=" + query,
    Http.getAuthToken()
  );
};
export const SendImgData = (data) => {
  console.log(data, "datasofimg");
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "uuuuu");
  return Http.post(
    baseUrl + "/business_user/images/",
    data,
    Http.getAuthToken()
  );
};
export const sendTimeData = (date) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "sentt");
  return Http.post(
    baseUrl + "/business_user/create_working_hours",
    date,
    Http.getAuthToken()
  );
};

export const reviewsList = ({ page, limit, query }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/business/reviews?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query,
    Http.getAuthToken()
  );
};

export const postReviewList = ({ formData }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/review", formData, Http.getAuthToken());
};

export const postfoodMenu = (formData) => {
  console.log(formData, "erhb5tg");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/food_items",
    formData,
    Http.getAuthToken()
  );
};

export const getfoodMenu = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/business/food_items_list/?query=" + query, {});
};

export const postEnquiry = ({ formData }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/send_enquiry",
    formData,
    Http.getAuthToken()
  );
};

export const searchBussiness = ({
  query,
  limit,
  page,
  category,
  subcategory,
  location,
}) => {
  console.log(page, category, subcategory, 6543345);
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/business/business_by_keywords/?query=" +
      query +
      "&category=" +
      category +
      "&subcategory=" +
      subcategory +
      "&location=" +
      location +
      "&page=" +
      page +
      "&limit=" +
      limit,
    {}
  );
};

export const getRelatatedBussiness = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/business/related_business/", query);
};
export const postFavBusiness = (sendId) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user/user_favorite_business",
    sendId,
    Http.getAuthToken()
  );
};

/* Amenties Post And Get Api */
export const postAmentiesList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/create_amenities",
    formData,
    Http.getAuthFileToken()
  );
};

export const getAmentiesList = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/business/amenities_list/?query=" + query);
};

export const updateAmentiesApi = (query) => {
  console.log(query, "uyhjfsdfslkfs");
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/business/update_amenities/" + query.editedRow?.id,
    query.row,
    Http.getAuthFileToken()
  );
};

export const deleteAmentiesApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/business/delete_amenities/?query=" + query);
};

/* Offer Detail Api */
export const postOfferDetailApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/offerdetail",
    formData,
    Http.getAuthFileToken()
  );
};

export const getOfferDetailApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/business/offerdetail/?query=" + query);
};

export const postSpecialDetailApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/specialdeal",
    formData,
    Http.getAuthToken()
  );
};

export const getSpecialDetailApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/business/specialdeal/?query=" + query);
};
export const postDealDetailApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/business/dealdetail",
    formData,
    Http.getAuthFileToken()
  );
};

export const getDealDetailApi = (payload) => {
  console.log(payload.deal_query, "sdfakljsl");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/business/getdealdetail/?query=" +
      payload.deal_query +
      "&limit=" +
      payload.deal_limit +
      "&page=" +
      payload.deal_page
  );
};
