import { action } from "typesafe-actions";
import { AuthActionTypes } from "./auth.model";

export const loginUserRequest = (req) => action(AuthActionTypes.LOGIN_USER_REQUEST, req);
export const loginUserSuccess = (res) => action(AuthActionTypes.LOGIN_USER_SUCCESS, res);
export const loginUserFailure = (err) => action(AuthActionTypes.LOGIN_USER_FAILURE, err);

export const loginByMobileRequest = (req) => action(AuthActionTypes.LOGIN_BY_MOBILE_REQUEST, req);
export const loginByMobileSuccess = (res) => action(AuthActionTypes.LOGIN_BY_MOBILE_SUCCESS, res);
export const loginByMobileFailure = (err) => action(AuthActionTypes.LOGIN_BY_MOBILE_FAILURE, err);

export const resetStateRequest = (req) => action(AuthActionTypes.STATE_RESET_REQUEST, req);
export const resetSuccess=(req)=> action(AuthActionTypes.LOGINSUCCESS_REQUEST, req)