/* External Import */
import React, { useState } from "react";
import { Col, Container, Row, Form, Input } from "react-bootstrap";

import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* Internal Imports */
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { timeSendRequest } from "../../store/bussiness/bussiness.action";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";


/* Internal Imports */

function WorkingHours({onNext}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const name = localStorage.getItem("username");
    let arabicStatus = i18n.language == "ar";
    /* Selectors */
    const { getBussinessDetails } = useSelector(bussnessCatSelector);
    /*  State */
    const [startDate, setStartDate] = useState(new Date());
  
    
    const defaultTimings = {
      from: "00:00",
      to: "00:00"
    };
    
    const getTimingsForDay = (day) => {
      const timings = getBussinessDetails?.timings?.[day];
      if (timings) {
        const parsedTimings = JSON.parse(timings);
        return {
          from: Object.values(parsedTimings)[0],
          to: Object.values(parsedTimings)[1],
        };
      }
      return defaultTimings;
    };
    
    const [date, setDate] = useState({
      monday: getTimingsForDay("monday"),
      tuesday: getTimingsForDay("tuesday"),
      wednesday: getTimingsForDay("wednesday"),
      thursday: getTimingsForDay("thursday"),
      friday: getTimingsForDay("friday"),
      saturday: getTimingsForDay("saturday"),
      sunday: getTimingsForDay("sunday"),
    });
    
    console.log(date, "datassssss");
    
    
    /* Fucntion */

    const dataUpdated = (day, type, value) => {
        console.log(day, type, value, "123");
        setDate((prevDate) => ({
          ...prevDate,
          [day]: {
            ...prevDate[day],
            [type]: value,
          },
        }));
      };
      const handleCheckboxChange = (day) => {
        setDate((prevDate) => ({
          ...prevDate,
          [day]: {
            ...prevDate[day],
            from: !prevDate[day].from ? "holiday" : "",
            to: !prevDate[day].to ? "holiday" : "",
          },
        }));
      };
    
      
    
      const handleTimeSubmit = (date) => {
        console.log(date, "ffff");
        date.arabicStatus = arabicStatus
        dispatch(timeSendRequest(date));
        onNext()
      };

    /* Form Data */
    const formik = useFormik({              
      });
    const { handleSubmit, handleChange, values, touched, errors } = formik;
    /* UseEffect */

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div class="add-listing-form form-space timing-listing-form mb-60 wow fadeInUp">
          <h4 class="title">{t("opening_hours")}</h4>
          <div class="row">
            {Object?.keys(date)?.map((day) => (
              <div class="col-lg-12" key={day}>
                <div class="timeing-list">
                  <Row>
                    <Col lg={4}>
                      <h5>{t(day)}</h5>
                    </Col>
                    <Col className="timing-section" lg={4}>
                      <div style={{ width: 300 }}>
                        <p style={{ marginTop: 10 }} className="label-tab">
                          {t("from")}
                        </p>
                        <input
                          type="time"
                          value={date[day].from}
                          disabled={date[day].from === "holiday"}
                          onChange={(e) =>
                            dataUpdated(day, "from", e.target.value)
                          }
                        />
                      </div>

                      <div style={{ width: 300, marginLeft: 12 }}>
                        <p style={{ marginTop: 10 }} className="label-tab">
                          {t("to")}
                        </p>
                        <input
                          type="time"
                          value={date[day].to}
                          disabled={date[day].to === "holiday"}
                          onChange={(e) =>
                            dataUpdated(day, "to", e.target.value)
                          }
                        />
                      </div>
                    </Col>
                    {console.log(date[day].to, day, "ASDFDS")}
                    <Col className="holiday" lg={12}>
                      <span className="label-holi">{t("holiday")}</span>
                      <Form.Check
                        className="cbox"
                        aria-label="option 1"
                        onChange={() => handleCheckboxChange(day)}
                        checked={date[day].to === "holiday" ? true : false}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
            <div class="button">
              <button
                type="button"
                class="main-btn icon-btn work-btn"
                onClick={() => handleTimeSubmit(date)}
              >
                {t("submit")+ " " + t("timing")}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default WorkingHours;
