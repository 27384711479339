import { UserDetailTypes, userDetailInitialState } from "./userdetail.model";
import Swal  from "sweetalert2";
import {authlogout} from "../../utils/traits";
const reducer = (state = userDetailInitialState, action) => {
  switch (action.type) {
    case UserDetailTypes.USERDETAIL_SEND_REQUEST:
      return {
        ...state,
        initLoading: true,
        sendSucces: false,
      };

    case UserDetailTypes.USERDETAIL_SEND_SUCCESS:
      return {
        ...state,
        initLoading: false,
        sendSucces: true,
      };
    case UserDetailTypes.USERDETAIL_SEND_FAILURE:
      return {
        ...state,
        initLoading: false,
        sendSucces: false,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_REQUEST:
      return {
        ...state,
        getinitLoading: true,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_SUCCESS:
      var { payload } = action;
      console.log(payload, "payloadesh");
      return {
        ...state,
        getinitLoading: false,
        getUserData: payload?.data.userDetails,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_FAILURE:
      return {
        ...state,
        getinitLoading: false,
      };

    case UserDetailTypes.RESETSTATE_SEND_REQUEST:
      return {
        ...state,
        getinitLoading: true,
        getUserData: [],
        otpVSucces:false
      };
      case UserDetailTypes.USER_VERIFYMOBILE_REQUEST:
        return {
          ...state,
          verifyinitLoading: true,
          otpSucces: false,
        };
  
      case UserDetailTypes.USER_VERIFYMOBILE_SUCCESS:
        return {
          ...state,
          verifyinitLoading: false,
          otpSucces: true,
        };
      case UserDetailTypes.USER_VERIFYMOBILE_FAILURE:
        var { payload } = action;
        if(payload?.response?.data?.auth=="deleted"){
          authlogout(payload)
        }
        console.log(payload?.response,"payload")
        return {
          ...state,
          verifyinitLoading: false,
          otpSucces: false,
        };

      case UserDetailTypes.USER_VERIFYMOBILEOTP_REQUEST:
        return {
          ...state,
          verifyotpinitLoading: true,
          otpVSucces: false,
        };
  
      case UserDetailTypes.USER_VERIFYMOBILEOTP_SUCCESS:
        return {
          ...state,
          verifyotpinitLoading: false,
          otpVSucces: true,
        };
      case UserDetailTypes.USER_VERIFYMOBILEOTP_FAILURE:
        var { payload } = action;
        if(payload?.response?.data?.auth=="deleted"){
          authlogout(payload)
        }
        console.log(payload?.response,"payload")
        return {
          ...state,
          verifyotpinitLoading: false,
          otpVSucces: false,
        };

    default:
      return state;
  }
};
export { reducer as userDetailReducer };
