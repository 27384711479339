import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAnglesRight,
  faHippo,
  faLocationDot,
  faMagnifyingGlass,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
/** Internal Imports */
import { businessRegisterValidator } from "../../validators/Validators";
/** Selectors */
import { getUserInfo } from "../../store/business_user/auth.selector";
/** API Calls */
import { categoryListRequest } from "../../store/category/category.action";
import { categorySelector } from "../../store/category/category.model";
import { getBusinessRegisterLoading } from "../../store/business_user/business_user_details.selector";
import { registerBusinessRequest } from "../../store/business_user/business_user_details.action";
import { getUserAllInfo } from "../../store/business_user/auth.selector";
import { useTranslation } from "react-i18next";
function RegisterBusiness({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  let arabicStatus = i18n.language == "ar"
  /** Selectors */
  const { categories } = useSelector(categorySelector);
  // const { isBusinessRegisterLoading } = useSelector((state) => {
  //   return {
  //     isBusinessRegisterLoading: getBusinessRegisterLoading(state),
  //     userLoginData: getUserAllInfo(state),
  //   };
  // });

  const mobile = localStorage.getItem("mobile");

  /** useEffect Hooks */
  useEffect(() => {
    dispatch(categoryListRequest({}));
  }, []);

  /** States */
  const [isClicked, setIsClicked] = useState(false);
  const [isSubClicked, setIsSubClicked] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [subCategoryID, setsubCategoryID] = useState();

  /** Form  Init values */

  const initialValues = {
    name_of_the_business: "",
    email: "",
    door_no: "",
    street: "",
    area: "",
    city: "",
    state: "",
    pin_code: "",
    address: "",
  };

  /* Function */

  const onSubmit = (formData) => {
    console.log(formData, "FASDFASDF");
    formData.mobile = mobile;
    formData.category_id = Number(categoryID);
    formData.sub_category_id = Number(subCategoryID);
    console.log(formData, "BusinessRegisterData");
    formData.arabicStatus = arabicStatus
    dispatch(registerBusinessRequest({ formData }));
  };

  /** Form Data */
  const formik = useFormik({
    initialValues,
    validationSchema: businessRegisterValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Row className="justify-content-center mt-5">
          <Col lg={8}>
            <div className="add-listing-form details-listing-form wow fadeInUp">
              <h4 className="title">Register Information</h4>
            </div>

            <Row>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">Business Name:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your Business Name"
                    name="name_of_the_business"
                    value={values.name_of_the_business}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.name_of_the_business}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">Email :</label>
                  <input
                    type="email"
                    className="form_control"
                    placeholder="Your Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.email}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">Category:</label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setCategoryID(e.target.value);
                    }}
                    value={categoryID}
                    name="category"
                    placeholder="Category"
                  >
                    {!isClicked ? <option>SELECT</option> : ""}
                    {categories &&
                      categories?.data?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  <p className="text-danger">{errors.category}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">Sub Category:</label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setsubCategoryID(e.target.value);
                    }}
                    value={subCategoryID}
                    name="sub_category"
                    placeholder="subCategory"
                  >
                    {!isSubClicked ? <option>SELECT</option> : ""}
                    {categories &&
                      categories?.data?.map((fields) => {
                        return <option value={fields.id}>{fields.name}</option>;
                      })}
                  </Form.Control>
                  <p className="text-danger">{errors.sub_category}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">Door No:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your Door No"
                    name="door_no"
                    value={values.door_no}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.door_no}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">Street :</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your Street"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.street}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">Area:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your Area"
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.area}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">City :</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.city}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">State:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Your State"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.state}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">Pin Code:</label>
                  <input
                    type="number"
                    className="form_control"
                    placeholder="Your Pin Code"
                    name="pin_code"
                    value={values.pin_code}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.pin_code}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="filled form-group tooltip-end-top mt-3">
                  <label className="label-style">Address:</label>
                  <Form.Control
                    as="textarea"
                    name="address"
                    placeholder="Your Address"
                  />
                  <p className="text-danger">{errors.address}</p>
                </div>
              </Col>
            </Row>
            {/* <div class="button text-center mt-2"> */}
            <button type="submit">Submit</button>
            {/* </div> */}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default RegisterBusiness;
