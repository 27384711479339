import { action } from "typesafe-actions";
import { CategoryActionTypes } from "./category.model";

export const categoryListRequest = (req) =>
  action(CategoryActionTypes.CATEGORY_LIST_REQUEST, req);
export const categoryListSuccess = (res) =>
  action(CategoryActionTypes.CATEGORY_LIST_SUCCESS, res);
export const categoryListFailure = (err) =>
  action(CategoryActionTypes.CATEGORY_LIST_FAILURE, err);

export const subcategoryListRequest = (req) =>
  action(CategoryActionTypes.SUBCATEGORY_LIST_REQUEST, req);
export const subcategoryListSuccess = (res) =>
  action(CategoryActionTypes.SUBCATEGORY_LIST_SUCCESS, res);
export const subcategoryListFailure = (err) =>
  action(CategoryActionTypes.SUBCATEGORY_LIST_FAILURE, err);
