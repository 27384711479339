import React, { useState, useEffect } from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faComment,
  faGripLinesVertical,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
/** Internal Exports */
import art from "../assets/images/art.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/niceselect.css";
import "../assets/css/default.css";
import "../assets/fonts/themify-icons/themify-icons.css";
import { API_BASE } from "../services/config";
import app from "../assets/images/no_image.jpg";
import { settingSelector } from "../store/Setting/setting.model";
import { settingLocationRequest } from "../store/Setting/setting.action";
import { bussnessCatSelector } from "../store/bussiness/bussiness.model";
import { postFavBussinessRequest } from "../store/bussiness/bussiness.action";

const GridView = ({ bussiness }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bussinessRev, setBussinessRev] = useState(0);
  const isAuth = localStorage.getItem("isAuthenticated");
  const id = localStorage.getItem("id");
  let arabicStatus = i18n.language == "ar"
    /** Selectors */
    const {
      getBussinessDetails
    } = useSelector(bussnessCatSelector);
  const { location } = useSelector(settingSelector);
  /* States */
  const [userNumber, setUserNumber] = useState(
    bussiness?.mobile ?? ""
  );

  /** Functions */
  const getCity = (cityId) => {
    return location?.find((cityData) => cityData.id == cityId)?.name;
  }
  /** Use Effects */
  useEffect(() => {
    dispatch(settingLocationRequest({}));
  }, [])
  useEffect(() => {
    let average = 0;
    average = bussiness?.business_user_reviews.reduce((acc, review) => {
      return (
        acc +
        parseFloat(review?.review_points) /
        bussiness?.business_user_reviews?.length
      );
    }, 0);
    average = average.toFixed(2);
    average = parseFloat(average);
    setBussinessRev(average);
  }, [bussiness]);

  return (
    <>
      {/* <div class="listing-list-wrapper">
        <Row> */}
      <Col md={6} sm={12}>
        <div
          class="listing-item listing-grid-item-two mb-30 wow fadeInUp cursor_pointer"
          
        >
          <div class="listing-thumbnail">
            <div className="pic">
              <img
                src={
                  bussiness?.profile_imageurl != null
                    ? API_BASE + bussiness?.profile_imageurl
                    : app
                }
              />                          
            </div>
          
          </div>
          <div class="listing-content" onClick={() => navigate(`/detail/${bussiness.id}`)}>
          <Row>               
                <Col md={6}>
                  <h3 className="title link">
                    {(i18n.language == "ar") ? bussiness.arabic_name_of_the_business : bussiness.name_of_the_business}
                  </h3>
                </Col>
                <Col md={6}>
                  <div className="link d-flex align-items-end flex-column" onClick={(event) => {
                    console.log(isAuth)
                    if (isAuth == "true") {
                      const sendId = {
                        business_id: parseInt(id),
                      };
                      sendId.arabicStatus = arabicStatus
                      dispatch(postFavBussinessRequest(sendId));
                    } else {
                      Swal.fire({
                        title: t('login_to'),
                        text: t('login_error_msg'),
                        icon: "error",
                      });
                    }
                    event.stopPropagation();
                  }}>
                    <i className="ti-heart wishlist-icon"> </i>
                  </div>
                </Col>
              </Row>
            <div className="ratings">
              <ul className="ratings ratings-three">
                <li>
                  <span className="link">
                    <a href="#" className="bold-font">
                      {bussinessRev}
                    </a>
                  </span>
                </li>
                <Rating value={bussinessRev} precision={0.5} readOnly />
                <li>
                  <FontAwesomeIcon
                    className="location-info-icon"
                    icon={faGripLinesVertical}
                  />
                  <i className="ti-location-pin"></i>
                  {(i18n.language == "ar") ? bussiness.arabic_area +', '+ bussiness.arabic_city + ', ' + bussiness.arabic_state : bussiness.area +', '+ getCity(bussiness.city) + ', ' + bussiness.state }
                </li>
              </ul>
            </div>
            <div className="listingcard">
              <ul>
              {
                    (i18n.language == "ar") ?
                      bussiness?.arabic_keywords?.split("و")?.map((keys) => {
                        return <ol>{keys}</ol>;
                      })
                      :
                      bussiness?.keywords?.split(",")?.map((keys) => {
                        return <ol>{keys}</ol>;
                      })}
              </ul>
            </div>
            <div className="button-bg pd">
              <Button className="blue-btn mg right">
                <FontAwesomeIcon
                  icon={faPhone}
                  onClick={(event) => {
                    if (isAuth != "true") {
                      Swal.fire({
                        title: t('login_to'),
                        text: t('login_error_msg'),
                        icon: "error",
                      });
                    }
                    event.stopPropagation();
                  }}
                />
               {isAuth == "true" ? userNumber : t(("show_number"))}
              </Button>
              <Button className="voilet-btn mg">
                <FontAwesomeIcon icon={faWhatsapp} /> {t("chat")}
              </Button>    
              <Button className="lite-blue">
                <FontAwesomeIcon icon={faComment} /> {t("enquiry")}
              </Button>         
            </div>
          </div>
        </div>
      </Col>
      {/* </Row>
      </div> */}
    </>
  );
};

export default GridView;
