import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { businesssigninValidator } from "../validators/Validators";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loginUserRequest } from "../store/business_user/auth.action";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(businesssigninValidator),
  });

  const dispatch = useDispatch();

  const handleSignup = (formData) => {
    console.log(formData);
    dispatch(loginUserRequest(formData));
  };

  return (
    <>
      <div>Signup</div>
      <form onSubmit={handleSubmit(handleSignup)}>
        <div>
          <input
            type="text"
            name="mail_id"
            id="mail_id"
            placeholder="Mail id"
            {...register("mail_id")}
          />
          {errors?.mail_id?.message && (
            <div>
              <p className="error text-danger">{errors?.mail_id?.message}</p>
            </div>
          )}
        </div>
        <div>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            {...register("password")}
          />
          {errors?.password?.message && (
            <div>
              <p className="error text-danger">{errors?.password?.message}</p>
            </div>
          )}
        </div>
        <div className="text-end">
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </>
  );
};

export default Signup;
