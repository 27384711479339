export const BussinessActionType = {
  BUSSNESSCAT_LIST_REQUEST: "@@business/BUSSNESSCAT_LIST_REQUEST",
  BUSSNESSCAT_LIST_SUCCESS: "@@business/BUSSNESSCAT_LIST_SUCCESS",
  BUSSNESSCAT_LIST_FAILURE: "@@business/BUSSNESSCAT_LIST_FAILURE",

  LINKS_LIST_REQUEST: "@@business/LINKS_LIST_REQUEST",
  LINKS_LIST_SUCCESS: "@@business/LINKS_LIST_SUCCESS",
  LINKS_LIST_FAILURE: "@@business/LINKS_LIST_FAILURE",

  LINKSGET_LIST_REQUEST: "@@business/LINKSGET_LIST_REQUEST",
  LINKSGET_LIST_SUCCESS: "@@business/LINKSGET_LIST_SUCCESS",
  LINKSGET_LIST_FAILURE: "@@business/LINKSGET_LIST_FAILURE",

  DETAILPAGEDATA_REQUEST: "@@business/DETAILPAGEDATA_REQUEST",
  DETAILPAGEDATA_SUCCESS: "@@business/DETAILPAGEDATA_SUCCESS",
  DETAILPAGEDATA_FAILURE: "@@business/DETAILPAGEDATA_FAILURE",
  IMAGE_LIST_REQUEST: "@@business/IMAGE_LIST_REQUEST",
  IMAGE_LIST_SUCCESS: "@@business/IMAGE_LIST_SUCCESS",
  IMAGE_LIST_FAILURE: "@@business/IMAGE_LIST_FAILURE",

  WORKTYM_LIST_REQUEST: "@@business/WORKTYM_LIST_REQUEST",
  WORKTYM_LIST_SUCCESS: "@@business/WORKTYM_LIST_SUCCESS",
  WORKTYM_LIST_FAILURE: "@@business/WORKTYM_LIST_FAILURE",

  REVIEW_LIST_REQUEST: "@@business/REVIEW_LIST_REQUEST",
  REVIEW_LIST_SUCCESS: "@@business/REVIEW_LIST_SUCCESS",
  REVIEW_LIST_FAILURE: "@@business/REVIEW_LIST_FAILURE",

  POSTREVIEW_LIST_REQUEST: "@@business/POSTREVIEW_LIST_REQUEST",
  POSTREVIEW_LIST_SUCCESS: "@@business/POSTREVIEW_LIST_SUCCESS",
  POSTREVIEW_LIST_FAILURE: "@@business/POSTREVIEW_LIST_FAILURE",

  POSTMENU_LIST_REQUEST: "@@business/POSTMENU_LIST_REQUEST",
  POSTMENU_LIST_SUCCESS: "@@business/POSTMENU_LIST_SUCCESS",
  POSTMENU_LIST_FAILURE: "@@business/POSTMENU_LIST_FAILURE",

  GETMENU_LIST_REQUEST: "@@business/GETMENU_LIST_REQUEST",
  GETMENU_LIST_SUCCESS: "@@business/GETMENU_LIST_SUCCESS",
  GETMENU_LIST_FAILURE: "@@business/GETMENU_LIST_FAILURE",

  POSTENQUIRY_LIST_REQUEST: "@@business/POSTENQUIRY_LIST_REQUEST",
  POSTENQUIRY_LIST_SUCCESS: "@@business/POSTENQUIRY_LIST_SUCCESS",
  POSTENQUIRY_LIST_FAILURE: "@@business/POSTENQUIRY_LIST_FAILURE",

  GETRELDBUSSINESS_LIST_REQUEST: "@@business/GETRELDBUSSINESS_LIST_REQUEST",
  GETRELDBUSSINESS_LIST_SUCCESS: "@@business/GETRELDBUSSINESS_LIST_SUCCESS",
  GETRELDBUSSINESS_LIST_FAILURE: "@@business/GETRELDBUSSINESS_LIST_FAILURE",

  SEARCH_REQUEST: "@@business/SEARCH_REQUEST",
  SEARCH_SUCCESS: "@@business/SEARCH_SUCCESS",
  SEARCH_FAILURE: "@@business/SEARCH_FAILURE",

  POSTFAVBUSINESS_REQUEST: "@@business/POSTFAVBUSINESS_REQUEST",
  POSTFAVBUSINESS_SUCCESS: "@@business/POSTFAVBUSINESS_SUCCESS",
  POSTFAVBUSINESS_FAILURE: "@@business/POSTFAVBUSINESS_FAILURE",

  /* Amenties Post And Get */
  POSTAMENTIES_REQUEST: "@@business/POSTAMENTIES_REQUEST",
  POSTAMENTIES_SUCCESS: "@@business/POSTAMENTIES_SUCCESS",
  POSTAMENTIES_FAILURE: "@@business/POSTAMENTIES_FAILURE",

  GETAMENTIES_REQUEST: "@@business/GETAMENTIES_REQUEST",
  GETAMENTIES_SUCCESS: "@@business/GETAMENTIES_SUCCESS",
  GETAMENTIES_FAILURE: "@@business/GETAMENTIES_FAILURE",

  PUT_AMENTIES_REQUEST: "@@bussiness/PUT_AMENTIES_REQUEST",
  PUT_AMENTIES_SUCCESS: "@@bussiness/PUT_AMENTIES_SUCCESS",
  PUT_AMENTIES_FAILURE: "@@bussiness/PUT_AMENTIES_FAILURE",

  RESET_REQUEST: "@@business/RESET_REQUEST",

  DELETE_AMENTIES_REQUEST: "@@bussiness/DELETE_AMENTIES_REQUEST",
  DELETE_AMENTIES_SUCCESS: "@@bussiness/DELETE_AMENTIES_SUCCESS",
  DELETE_AMENTIES_FAILURE: "@@bussiness/DELETE_AMENTIES_FAILURE",

  OFFERDETAILPOST_REQUEST: "@@bussiness/OFFERDETAILPOST_REQUEST",
  OFFERDETAILPOST_SUCCESS: "@@bussiness/OFFERDETAILPOST_SUCCESS",
  OFFERDETAILPOST_FAILURE: "@@bussiness/OFFERDETAILPOST_FAILURE",

  OFFERDETAILGET_REQUEST: "@@bussiness/OFFERDETAILGET_REQUEST",
  OFFERDETAILGET_SUCCESS: "@@bussiness/OFFERDETAILGET_SUCCESS",
  OFFERDETAILGET_FAILURE: "@@bussiness/OFFERDETAILGET_FAILURE",

  SPECIALDEALSPOST_REQUEST: "@@bussiness/SPECIALDEALSPOST_REQUEST",
  SPECIALDEALSPOST_SUCCESS: "@@bussiness/SPECIALDEALSPOST_SUCCESS",
  SPECIALDEALSPOST_FAILURE: "@@bussiness/SPECIALDEALSPOST_FAILURE",

  SPECIALDEALSGET_REQUEST: "@@bussiness/SPECIALDEALSGET_REQUEST",
  SPECIALDEALSGET_SUCCESS: "@@bussiness/SPECIALDEALSGET_SUCCESS",
  SPECIALDEALSGET_FAILURE: "@@bussiness/SPECIALDEALSGET_FAILURE",

  DEALDETAILPOST_REQUEST: "@@bussiness/DEALDETAILPOST_REQUEST",
  DEALDETAILPOST_SUCCESS: "@@bussiness/DEALDETAILPOST_SUCCESS",
  DEALDETAILPOST_FAILURE: "@@bussiness/DEALDETAILPOST_FAILURE",

  DEALDETAILGET_REQUEST: "@@bussiness/DEALDETAILGET_REQUEST",
  DEALDETAILGET_SUCCESS: "@@bussiness/DEALDETAILGET_SUCCESS",
  DEALDETAILGET_FAILURE: "@@bussiness/DEALDETAILGET_FAILURE",
};

export const bussinessCatInitialState = {
  getBussinessDetails: [],
  isgetLinkLoading: false,
  isLoading: false,
  isLinkLoading: false,
  isImgLoading: false,
  isWortTimeLoading: false,
  isReviewLoading: false,
  isPostReveiwLoading: false,
  reviewData: [],
  reviewCount: 0,
  isMenuLoading: false,
  isgetMenuLoading: false,
  getMenuData: [],
  isenquiryLoading: false,
  busscat: [],
  isgetRelBusinessLoading: false,
  getRelatedBussiness: [],
  isFavBusLoading: false,
  postSuccess: false,
  postamentiesLoading: false,
  amentisSuccess: false,
  getAmentiesLoading: false,
  getAmentiesData: [],
  updateAmentisLoading: false,
  deleteAmentiesLoading: false,
  getFilterBusinessData: [],
  detailPageData: [],
  offerPostLoading: false,
  offerGetLoading: false,
  successOffer: false,
  dealspostLoading: false,
  dealsgetLoading: false,
  successDeals: false,
  dealPostLoading: false,
  successDeal: false,
  getDealData: [],
  dealGetLoading: false,
};

export const bussnessCatSelector = (state) => state.BussCatReducer;
