export const Days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const app_icon = "../assets/images/app_icon.png";

export const menuType = [
  { id: 1, name: "veg" },
  { id: 2, name: "non_veg" },
];
