import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoute from "./routes/route";
import { authProtectedRoutes, publicRoutes } from "./routes/";

function App() {
  return (
    <Router>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route  exact path={route?.path} element={<route.component />} />
        ))}
        <Route path="/" element={<AppRoute />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route exact path={route?.path} element={<route.component />} />
          ))}
        </Route>        
        <Route
          path="*"
          element={
            <div className="text-center d-flex justify-content-center align-items-center s100vh">
              {" "}
              <b> Not Found !! </b>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
