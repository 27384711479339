import React, { useState, useEffect, useMemo, useDebugValue } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  Modal,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCross,
  faTimes,
  faUser,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
/** Internal Imports */
import default_user from "../../assets/images/user.png";
import {
  UserDetailValidator,
  businessRegisterValidator,
} from "../../validators/Validators";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import {
  getUserDetailRequest,
  postuserDetailsRequest,
  verifyuserMobileRequest,
  verifyuserMobileOTPRequest,
  resetStatesRequest,
} from "../../store/UserDetails/userdetail.action";
import { userDetailSelector } from "../../store/UserDetails/userdetail.model";
import { API_BASE } from "../../services/config";
import { resetStateRequest } from "../../store/business_user/auth.action";
import { settingSelector } from "../../store/Setting/setting.model";
import { settingLocationRequest } from "../../store/Setting/setting.action";
import _ from "lodash";

function UserDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const email = localStorage.getItem("email");
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem("role");
  const [logoutShow, setlogoutShow] = useState(false);
  const [otpShow, setotpShow] = useState(false);

  let arabicStatus = i18n.language == "ar";
  const navigateName =
    role == 2
      ? t("bussiness") + " " + t("information")
      : t("user") + " " + t("information");
  const navigateUrl =
    role == 2 ? "/business_details/" + id : "/userdetail/" + id;
  /** Selectors */
  const { getUserData, sendSucces, otpVSucces } = useSelector(userDetailSelector);
  const { location } = useSelector(settingSelector);
  console.log(getUserData, 43987);
  /** States */
  const [image, setImage] = useState();
  const [langSwitch, setLangSwitch] = useState(1);
  const [enteredOTP, setenteredOTP] = useState(null);

  console.log(langSwitch, "langSwitch");
  const [cityId, setCityID] = useState(
    getUserData?.city ? getUserData.city : ""
  );
  const [countryName, setCountryName] = useState("Saudi Arabia");
  const [countryCode, setCountryCode] = useState("sa");
  const [mobileverified, setMobileverified] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [errorMsg, seterrorMsg] = useState({
    doj: false,
    ele: false,
  });
  const [getcheck, setGetCheck] = useState();
  console.log(errorMsg, 43957894);
  /** Submit data */
  const onSubmit = (formData) => {
    // formData.dob = startDate;
    // formData.martial_status = getcheck;
    // formData.profile_imageurl = image;
    // formData.city = cityId;
    // formData.country = countryName;
    formData.country_code = countryCode;
    formData.language_type = langSwitch || 1;
    formData.arabicStatus = arabicStatus;
    console.log(formData, "FormDataPOST");
    // return false;
    if (errorMsg.doj != true && errorMsg.ele != true)
      dispatch(postuserDetailsRequest({ formData }));
  };

  /** Submit the form to login */

  const initialValues = useMemo(() => {
    return {
      name: getUserData?.name || "",
      last_name: getUserData?.last_name || "",
      email: getUserData?.email || "",
      mobile: getUserData?.mobile || "",
      pin_code: getUserData?.pin_code || "",
      address: getUserData?.address || "",
      national_id: getUserData?.national_id || "",
      arabic_name: getUserData?.arabic_name || "",
      arabic_lastname: getUserData?.arabic_lastname || "",
      arabic_city: getUserData?.arabic_city || "",
      country: getUserData?.country || countryName,
      arabic_address: getUserData?.arabic_address || "",
      arabic_address2: getUserData?.arabic_address || "",
      address2: getUserData?.address2 || "",
    };
  }, [getUserData]);

  /** Form Data */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserDetailValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, errors, resetForm, setValues } =
    formik;

  /**useEffect */

  useEffect(() => {
    dispatch(settingLocationRequest({}));
  }, []);
  useEffect(() => {
    console.log(errors, "errors");
  }, [errors]);

  useEffect(() => {
    console.log(id, "1111j");

    dispatch(getUserDetailRequest(id));
  }, [id]);

  useEffect(() => {
    if (sendSucces == true) {
      dispatch(getUserDetailRequest(id));
      // resetForm()
    }
    if(otpVSucces==true){
      dispatch(resetStatesRequest())
      dispatch(getUserDetailRequest(id));
      setotpShow(!otpShow);
    }
  }, [id, sendSucces, otpVSucces]);

  useEffect(() => {
    setLangSwitch(getUserData?.language_type || 1);
    setCityID(getUserData?.city);
    setCountryCode(getUserData?.country_code || "sa");
    setMobileverified(getUserData?.mobile_verified);
    // setStartDate(getUserData?.dob);
    // setGetCheck(getUserData?.martial_status);
    setValues({
      name: getUserData?.name || "",
      last_name: getUserData?.last_name || "",
      arabic_name: getUserData?.arabic_name || "",
      arabic_lastname: getUserData?.arabic_lastname || "",
      arabic_city: getUserData?.arabic_city || "",
      arabic_address: getUserData?.arabic_address || "",
      arabic_address2: getUserData?.arabic_address2 || "",
      email: getUserData?.email || "",
      mobile: getUserData?.mobile || "",
      pin_code: getUserData?.pin_code || "",
      address: getUserData?.address || "",
      address2: getUserData?.address2 || "",
      national_id: getUserData?.national_id || "",
      country: getUserData?.country || countryName,
    });
  }, [getUserData]);

  /** Functions */
  const handleUpdate = (event) => {
    setenteredOTP(event.target.value);
  };

  const handlevalueChange = (event) => {
    let num = parseInt(event);
    setGetCheck(num);
  };
  const phoneInputchange = (value, country, e, formattedValue) => {
    console.log(value, country, e, formattedValue);
    values.mobile = formattedValue;
    values.country = country?.name;
    setValues(values);
  };

  const Openverification = (verified) => {
    if (verified == 0 && values.mobile != "") {
      let mobile_no = _.replace(values.mobile,"(","");
      mobile_no = _.replace(mobile_no,")","");
      mobile_no = _.replace(mobile_no,/ /g,"");
      mobile_no = _.replace(mobile_no,/-/g,"");
      const formData = { mobile: mobile_no };
      console.log(formData,"formData");
      // return false;
      dispatch(verifyuserMobileRequest(formData));
      if(!otpShow)
        setotpShow(!otpShow);
    }
  };

  const OpenOTPverification=()=>{
    if(enteredOTP!=""){
      let formData = { otp: enteredOTP};
      dispatch(verifyuserMobileOTPRequest(formData));
    }
  };
  const logoutBusiness = () => {
    localStorage.clear();
    setlogoutShow(!logoutShow);
    navigate("/");
    dispatch(resetStateRequest());
  };

  console.log(API_BASE + getUserData.profile_imageurl, 34785);
  /** useEffect */
  useEffect(() => {
    if (arabicStatus) setLangSwitch(2);
    else setLangSwitch(1);
  }, [arabicStatus]);

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Header />
        <Container className="mt-3">
          <Row>
            <Col md={3} lg={3} className="p-0 ">
              <div>
                <section>
                  <Container>
                    <div>
                      <div className="profile-div">
                        <Row>
                          <Col md={4} lg={3} sm={1} className="p-0 width-10">
                            <div className="profile-pic">
                              <img
                                src={
                                  getUserData.profile_imageurl != null &&
                                  getUserData.profile_imageurl != ""
                                    ? API_BASE + getUserData.profile_imageurl
                                    : default_user
                                }
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col md={8} lg={8} sm={9}>
                            <div>
                              <p>{t("hello")}</p>
                              <h5>
                                {i18n.language == "ar"
                                  ? getUserData?.general?.arabic_name ??
                                    t("welcome") + " " + t("user")
                                  : getUserData?.general?.name ??
                                    t("welcome") + " " + t("user")}
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="profile-listing-card">
                        <ul>
                          <li onClick={() => navigate(navigateUrl)}>
                            <FontAwesomeIcon
                              className="profile-listing-icon mx-2"
                              icon={faUser}
                            />
                            {navigateName}
                          </li>
                          <li
                            onClick={() => {
                              logoutBusiness();
                            }}
                          >
                            <FontAwesomeIcon
                              className="profile-listing-icon mx-2"
                              icon={faRightFromBracket}
                            />
                            {t("logout")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Container>
                </section>
              </div>
            </Col>
            <Col md={9} lg={9}>
              <Row className="bgcol">
                <Col lg={11}>
                  <div className="add-listing-form details-listing-form wow fadeInUp">
                    <Row>
                      <Col md={6}>
                        <h4 className="title">
                          {t("user") + " " + t("information")}
                        </h4>
                      </Col>
                      <Col md={6}>
                        <div
                          className={`${
                            arabicStatus ? "text-start" : "text-end"
                          }`}
                        >
                          {langSwitch == 1 ? (
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => setLangSwitch(2)}
                            >
                              اﻟﻌﺮﺑﻴﺔ
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => setLangSwitch(1)}
                            >
                              English
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {console.log(values, 478546)}
                  <Row>
                    <Col lg={6}>
                      <div
                        className={
                          langSwitch == 1 ? "form_group" : "form_group hide"
                        }
                      >
                        <label className="label-style">
                          {t("first_name")} :
                        </label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("first_name")}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus ? errors.name?.ar : errors.name?.en}
                        </p>
                      </div>

                      <div
                        className={
                          langSwitch == 1 ? "form_group hide" : "form_group"
                        }
                      >
                        <label className="label-style">
                          {t("first_name")} :
                        </label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("first_name")}
                          name="arabic_name"
                          value={values.arabic_name}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.arabic_name?.ar
                            : errors.arabic_name?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className={
                          langSwitch == 1 ? "form_group " : "form_group hide"
                        }
                      >
                        <label className="label-style">
                          {t("last_name")} :
                        </label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("last_name")}
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.last_name?.ar
                            : errors.last_name?.en}
                        </p>
                      </div>
                      <div
                        className={
                          langSwitch == 1 ? "form_group hide" : "form_group"
                        }
                      >
                        <label className="label-style">
                          {t("last_name")} :
                        </label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("last_name")}
                          name="arabic_lastname"
                          value={values.arabic_lastname}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.arabic_lastname?.ar
                            : errors.arabic_lastname?.en}
                        </p>
                      </div>
                    </Col>
                    {/* <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("dob")} :</label>
                        <DatePicker
                          className="date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        {errorMsg?.doj && (
                          <p className="text-danger">{t("dob") + " " + t("is") + " " + t("required")}</p>
                        )}
                      </div>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="form_group">
                        <label className="label-style">{t("email")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("email")}
                          name="email"
                          value={getUserData?.email}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <Row>
                        <Col lg={9}>
                          <div class="form_group">
                            <label className="label-style">
                              {t("mobile")} :
                            </label>
                            {/* <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("mobile") + " " + t("no")}
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                        /> */}
                            <PhoneInput
                              country={countryCode}
                              inputProps={{
                                name: "mobile",
                                className: "data_phone form_control",
                              }}
                              value={values.mobile}
                              onKeyDown={handleChange}
                              onChange={phoneInputchange}
                            />
                            <p className="text-danger">
                              {arabicStatus
                                ? errors.mobile?.ar
                                : errors.mobile?.en}
                            </p>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div class="form_group">
                            <label className="label-style">&nbsp;</label>
                            <div>
                              <FontAwesomeIcon
                                className={
                                  mobileverified == 1
                                    ? "verified_data"
                                    : "unverified_data"
                                }
                                icon={mobileverified == 1 ? faCheck : faTimes}
                                title={
                                  mobileverified == 1
                                    ? "Verified"
                                    : "Verify Now"
                                }
                                onClick={() => {
                                  Openverification(mobileverified);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col lg={6}>
                      <div className="form_group">
                        <label className="label-style">{t("martial_status")} :</label>

                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3 d-flex">
                              <Form.Check
                                inline
                                label={t("single")}
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                value={1}
                                checked={getUserData.martial_status == 1}
                                onChange={(e) =>
                                  handlevalueChange(e.target.value)
                                }
                              />

                              <Form.Check
                                block
                                label={t("married")}
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                checked={getUserData.martial_status == 2}
                                value={2}
                                onChange={(e) =>
                                  handlevalueChange(e.target.value)
                                }
                              />
                            </div>
                          ))}
                        </Form>
                        {errorMsg?.ele && (
                          <p className="text-danger">
                            {" "}
                            {t("martial_status") + " " + t("required")}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="filled form_group tooltip-end-top">
                        <label className="label-style">{t("profile") + " "+ t("image")}:</label>
                        <Form.Control
                          type="file"
                          className="proimg"
                          accept="image/*"
                          name="profile_imageurl"
                          onChange={(e) => setImage(e.target.files[0])}
                          placeholderText="Upload Certificate"
                        />
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    {/* <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("country")} :</label>
                        <Form.Control
                          className="dn-space border-business"
                          as="select"
                          onChange={(e) => {
                            handleCityChange(e);
                          }}
                          value={countryId}
                          name="country"
                          placeholder={t("select")+ " " + t("country")}
                        >
                          {!isClicked ? <option>{t("select")}</option> : ""}
                          {location &&
                            location?.map((fields) => {
                              return (
                                <option value={fields.id} key={fields.id}>                                  
                                  {langSwitch == 2 ? fields.arabic_name ?? fields.name : fields.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                        {!countryId && (
                          <p className="text-danger">{t("country") + " " + t("is") + " " +  t("required")}</p>
                        )}
                      </div>
                    </Col> */}
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">
                          {t("national_id")} :
                        </label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("national_id")}
                          name="national_id"
                          value={values.national_id}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.national_id?.ar
                            : errors.national_id?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className={
                          langSwitch == 1
                            ? "filled form-group tooltip-end-top "
                            : "filled form-group tooltip-end-top hide"
                        }
                      >
                        <label className="label-style">
                          {t("address")} 1 :
                        </label>
                        <Form.Control
                          as="textarea"
                          className="address1"
                          rows="1"
                          name="address"
                          placeholder={t("address")}
                          value={values.address}
                          onChange={handleChange}
                        />
                        <p className="text-danger">{errors.address}</p>
                      </div>
                      <div
                        className={
                          langSwitch == 1
                            ? "filled form-group tooltip-end-top hide"
                            : "filled form-group tooltip-end-top"
                        }
                      >
                        <label className="label-style">
                          {t("address")} 1 :
                        </label>
                        <Form.Control
                          as="textarea"
                          className="arabic_address"
                          rows="1"
                          name="arabic_address"
                          placeholder={t("address")}
                          value={values.arabic_address}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.arabic_address?.ar
                            : errors.arabic_address?.en}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div
                        className={
                          langSwitch == 1
                            ? "filled form-group tooltip-end-top "
                            : "filled form-group tooltip-end-top hide"
                        }
                      >
                        <label className="label-style">
                          {t("address")} 2 :
                        </label>
                        <Form.Control
                          as="textarea"
                          className="address1"
                          rows="1"
                          name="address2"
                          placeholder={t("address")}
                          value={values.address2}
                          onChange={handleChange}
                        />
                        <p className="text-danger">{errors.address}</p>
                      </div>
                      <div
                        className={
                          langSwitch == 1
                            ? "filled form-group tooltip-end-top hide"
                            : "filled form-group tooltip-end-top"
                        }
                      >
                        <label className="label-style">
                          {t("address")} 2 :
                        </label>
                        <Form.Control
                          as="textarea"
                          className="arabic_address"
                          rows="1"
                          name="arabic_address2"
                          placeholder={t("address")}
                          value={values.arabic_address2}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.arabic_address?.ar
                            : errors.arabic_address?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        class="form_group"
                        className={
                          langSwitch == 1 ? "form_group " : "form_group hide"
                        }
                      >
                        <label className="label-style">{t("city")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("city")}
                          name="city"
                          value={getUserData?.city}
                          onChange={handleChange}
                        />
                        {/* <Form.Control
                          className="border-business"
                          as="select"
                          onChange={(e) => {
                            handleCityChange(e);
                          }}
                          value={cityId}
                          name="city"
                          placeholder={t("select")+ " " + t("city")}
                        >
                          {!isClicked ? <option>{t("select")}</option> : ""}
                          {location &&
                            location?.map((fields) => {
                              return (
                                <option value={fields.id} key={fields.id}>                                  
                                  {langSwitch == 2 ? fields.arabic_name ?? fields.name : fields.name}
                                </option>
                              );
                            })}
                        </Form.Control> */}
                      </div>
                      <div
                        className={
                          langSwitch == 1 ? "form_group hide" : "form_group"
                        }
                      >
                        <label className="label-style">{t("city")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("city")}
                          name="arabic_city"
                          value={values.arabic_city}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.arabic_city?.ar
                            : errors.arabic_city?.en}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("country")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("country")}
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.country?.ar
                            : errors.country?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("pin_code")} :</label>
                        <input
                          type="number"
                          className="form_control"
                          placeholder={t("pin_code")}
                          name="pin_code"
                          value={values.pin_code}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? errors.pin_code?.ar
                            : errors.pin_code?.en}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div class="button mt-3">
                    <button
                      class="main-btn"
                      type="submit"
                      onClick={() => {
                        // if (!startDate || !getcheck) {
                        //   seterrorMsg({
                        //     doj: !startDate,
                        //     ele: !getcheck,
                        //   });
                        // } else {
                        seterrorMsg({
                          doj: false,
                          ele: false,
                        });
                        // }
                      }}
                    >
                      {t("submit")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
      <Footer />
      <Modal show={otpShow} onHide={() => setotpShow(!otpShow)}>
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        setotpShow(!otpShow);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                  <h4 className="title">{t("verify_phone")}</h4>
                  <Row>
                    <Col lg={8}>
                      <div class="form_group">
                        <label className="label-style">{t("otp")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("otp")}
                          name="otp"
                          value={enteredOTP}
                          onChange={handleUpdate}
                        />
                        <p className="text-danger"></p>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div class="form_group">
                        <label className="label-style">&nbsp;</label>
                        <div>
                          <button class="main-btn" onClick={(e) => {e.preventDefault();Openverification(mobileverified)}}>
                            {t("resend")}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <div class="button">
                      <button type="submit" class="main-btn" onClick={(e) => {e.preventDefault();OpenOTPverification()}}>
                        {t("submit")}
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default UserDetail;
