/** External Imports */
import "../assets/css/main-pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faHippo,
  faLocationDot,
  faMagnifyingGlass,
  faMobile,
  faUtensils,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  NavLink,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Dropdown from 'rsuite/Dropdown'; 
import 'rsuite/dist/rsuite.min.css';

/** Internal Imports */
import noimages from "../assets/images/shopping.jpg";

import Header from "./layout/Header";
import {
  categoryListRequest,
  subcategoryListRequest,
} from "../store/category/category.action";
import { categorySelector } from "../store/category/category.model";
import { API_BASE } from "../services/config";
import {
  getDealDetailRequest,
  reseDataRequest,
  searchRequest,
} from "../store/bussiness/bussiness.action";
import { bussnessCatSelector } from "../store/bussiness/bussiness.model";
import {
  loginByMobileRequest,
  loginUserRequest,
  resetStateRequest,
  resetSuccess,
} from "../store/business_user/auth.action";
import {
  userRegisterRequest,
  userLoginRequest,
  resetUserDataRequest,
  loginStateReset,
} from "../store/user/user.action";
import { linkGetRequest } from "../store/bussiness/bussiness.action";

import Footer from "./layout/Footer";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import rest from "../assets/images/listing/rest.png";
import hot from "../assets/images/listing/hot.png";
import cafe from "../assets/images/listing/cafe-l.png";
import lounge from "../assets/images/listing/lounge-l.png";
import beauty from "../assets/images/listing/beauty.png";
import hosp from "../assets/images/listing/hosp.png";
import gym from "../assets/images/listing/gym.png";
import fun from "../assets/images/listing/fun.png";
import flights from "../assets/images/listing/flight.png";
import concerts from "../assets/images/listing/concert.png";
import advertisement from "../assets/images/advertisement.jpg";
import car from "../assets/images/listing/car.png";
import tour from "../assets/images/listing/tour.png";
import park from "../assets/images/listing/themepark.png";
import adventure from "../assets/images/listing/adventure.png";
import theatre from "../assets/images/listing/theatre.png";
import shopping from "../assets/images/listing/shopping.png";
import vision from "../assets/images/listing/Vision-2030-logo.png";
import {
  businesssigninValidator,
  businesssotpValidator,
} from "../validators/Validators";
import { authSelector } from "../store/business_user/auth.model";
import { userSelector } from "../store/user/user.model";
import { userDetailSelector } from "../store/UserDetails/userdetail.model";
import { getUserDetailRequest } from "../store/UserDetails/userdetail.action";
import {
  settingCountryRequest,
  settingLocationRequest,
  settingPlaceRequest,
  subLocationRequest,
} from "../store/Setting/setting.action";
import { settingSelector } from "../store/Setting/setting.model";

function Home() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [locationID, setLocationID] = useState(1);
  const [locationlabel, setLocationlabel] = useState("Location");
  const [city, setCity] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [logoutShow, setlogoutShow] = useState(false);
  const [userRegisterShow, setuserRegisterShow] = useState(false);
  const [userLoginShow, setuserLoginShow] = useState(false);
  const [otpShow, setotpShow] = useState(false);
  const [loginShow, setloginShow] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState({});
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");
  const navigateName =
    role == 2
      ? t("bussiness") + " " + t("information")
      : t("user") + " " + t("information");
  const navigateUrl =
    role == 2 ? "/business_details/" + id : "/userdetail/" + id;
  let arabicStatus = i18n.language == "ar";
  /** Selector Values */
  const { categories, subcaterory } = useSelector(categorySelector);
  const { isLoading, userLoginData, email, isLoginLoading } =
    useSelector(authSelector);
  const { isUserLoginLoading, loginSuccess, user_email } =
    useSelector(userSelector);
  const { getBussinessDetails, getDealData, dealGetLoading } =
    useSelector(bussnessCatSelector);
  const { getUserData } = useSelector(userDetailSelector);
  const { location, sub_location } = useSelector(settingSelector);
  console.log(sub_location, "sub_location");
  // const groupedOptions = [
  //   {
  //     label: 'Fruits',
  //     options: [
  //       { value: 'apple', label: 'Apple' },
  //       { value: 'banana', label: 'Banana' }
  //     ]
  //   },
  //   {
  //     label: 'Vegetables',
  //     options: [
  //       { value: 'carrot', label: 'Carrot' },
  //       { value: 'broccoli', label: 'Broccoli' }
  //     ]
  //   }
  // ];

  /** Sent OTP  */
  const initialValues = { email: "" };

  const onSubmit = (formData) => {
    dispatch(loginByMobileRequest(formData));
    setotpShow(false);
    setloginShow(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: businesssotpValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  /** Form 2 */
  const loginValues = { email: email, otp: "" };

  const onLogin = (formData) => {
    console.log(formData, "BUSINESSUSER");
    dispatch(loginUserRequest(formData));
  };

  const formik2 = useFormik({
    initialValues: loginValues,
    validationSchema: businesssigninValidator,
    onSubmit: onLogin,
  });
  const {
    handleSubmit: handleLogin,
    handleChange: handleUpdate,
    values: newvalues,
    touched: newtouched,
    errors: newerrors,
    setValues,
  } = formik2;

  /** User Register Form */
  const userRegisterValues = { email: "" };

  const onUserRegister = (formData) => {
    console.log(formData, "UserData");
    formData.arabicStatus = arabicStatus;
    dispatch(userRegisterRequest(formData));
    setuserRegisterShow(!userRegisterShow);
    setuserLoginShow(true);
  };

  const userFormik = useFormik({
    initialValues: userRegisterValues,
    validationSchema: businesssotpValidator,
    onSubmit: onUserRegister,
  });
  const {
    handleSubmit: handleuserRegisterSubmit,
    handleChange: handleuserRegisterFields,
    values: userregistervalues,
    touched: userregistertouched,
    errors: userregistererrors,
  } = userFormik;

  /** User Login Form */
  const userLoginValues = { email: user_email, otp: "" };
  console.log(user_email, "mobile");
  const onuserLogin = (formData) => {
    // formData.mobile = mobile;
    console.log(formData, "USERLOGDATA");
    formData.arabicStatus = arabicStatus;
    dispatch(userLoginRequest(formData));

    setuserLoginShow(false);
  };

  const userLogin = useFormik({
    initialValues: userLoginValues,
    validationSchema: businesssigninValidator,
    onSubmit: onuserLogin,
  });
  const {
    handleSubmit: handleuserLogin,
    handleChange: userLoginFileds,
    values: userLoginvalues,
    touched: userLogintouched,
    errors: userLoginerrors,
  } = userLogin;

  /** Use Effect */

  useEffect(() => {
    if (id) {
      role == 2
        ? dispatch(linkGetRequest(id))
        : dispatch(getUserDetailRequest(id));
    }
  }, [id, role]);

  useEffect(() => {
    newvalues.email = email;
  }, [email]);

  useEffect(() => {
    userLoginvalues.email = user_email;
  }, [user_email]);

  /** Function */
  const groupByLocation = sub_location?.rows?.reduce((acc, { location, name,arabic_name, id: subID }) => {
    if (location) { 
      const { id, name: locationName } = location;
      if (!acc[locationName]) {
        acc[locationName] = [];
      }
      acc[locationName].push({ value: subID, label: arabicStatus ? arabic_name :name,className:"options-class" });
    }
    console.log(acc,"accacc")
    return acc;
  }, {});
  
  
  const groupedOptions = groupByLocation && Object.entries(groupByLocation)?.map(([label, options]) => ({ label, options }));
  console.log(groupedOptions,"groupedOptions");
  /** Redirect */
  useEffect(() => {
    console.log(isAuth, role, "isAuth");
    if (role == 2) {
      console.log(
        isAuth,
        getBussinessDetails?.general?.id,
        "signLofsadsdading"
      );
      setUserDetailsData(getBussinessDetails?.general);

      if (isAuth && isLoginLoading == true) {
        console.log(isLoginLoading, "654");
        if (getBussinessDetails?.general?.id != undefined) {
          navigate("/business_details/" + id);
          dispatch(resetSuccess());
        }
      }
    } else if (role == 3) {
      console.log(role, loginSuccess, "rolllll");
      setUserDetailsData(getUserData);
      if (isAuth && loginSuccess == true) {
        console.log(loginSuccess, "lllll");
        console.log(getUserData);
        navigate("/userdetail/" + id);
        dispatch(loginStateReset());
      }
    }
  }, [
    isAuth,
    loginSuccess,
    getBussinessDetails,
    role,
    isLoginLoading,
    id,
    resetSuccess,
  ]);

  const handleQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };
  /** List Page Redirect */
  const listPage = () => {
    navigate({
      pathname: "list",
      search: createSearchParams({
        query: query,
        category: 0,
        subcategory: 0,
        location: locationID,
        page,
        limit,
      }).toString(),
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      listPage();
    }
  };
  const handleSearch = () => {
    console.log(query, "ksdhgsdif");
    listPage();
  };

  const subcategoryBusiness = (name) => {
    navigate({
      pathname: "list",
      search: createSearchParams({
        query: "",
        category: 0,
        subcategory: name,
        location: locationID,
        page,
        limit,
      }).toString(),
    });
  };
  const categoryBusiness = (categoryData) => {
    console.log(categoryData);
    navigate({
      pathname: "list",
      search: createSearchParams({
        query: categoryData?.name,
        category: categoryData?.id,
        subcategory: 0,
        location: locationID,
        page,
        limit,
      }).toString(),
    });
  };

  const handleLocationId = (eve) => {
    console.log(eve,"evee")
    setLocationlabel(eve.label)
    setLocationID(eve.value);
  };

  useEffect(() => {
    dispatch(categoryListRequest({}));
    dispatch(subcategoryListRequest({}));
    dispatch(
      getDealDetailRequest({
        deal_query: "",
        deal_page: 1,
        deal_limit: 4,
      })
    );
    dispatch(settingLocationRequest({}));
    dispatch(subLocationRequest({}));
  }, []);
  useEffect(() => {
    let datas =
      subcaterory?.data &&
      subcaterory?.data
        ?.filter((ele) => ele.category_id == 4)
        .map((fields) => {
          return fields;
        });
    setsubCategoryData(datas);
  }, [subcaterory]);

  /** Detect Location */
  const getCityName = async () => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;

    try {
      const response = await axios.get(url);
      const address = response.data.address;
      if (address) {
        if (address.city || address.town || address.village)
          setCity(address.city || address.town || address.village);
        else if (address.province) {
          let cityName = address.province.split(" ");
          setCity(cityName[0]);
        } else setCity("City not found");
      } else {
        setCity("No results found");
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
      setCity("Error fetching city name");
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
      getCityName();
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    location?.find((cityData) => {
      if (cityData.name === city) {
        setLocationID(cityData.id);
      } else {
        console.log(lat, lng, city, "City Details");
        setLocationID(1);
      }
    });
  }, [city]);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'green',
      paddingLeft: 20,
      background:state.isSelected ? 'green' : 'white',
      cursor:"pointer"
      
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

  return (
    <>
      <div className="main_body body_bg">
        <div className="index">
          <Header />
          <div className="search_section">
            <Container>
              <Row>
                <Col md={9}>
                  <div className="search_area">
                    <h1>
                      <a href="#">
                        {t("search_content")} <span>{t("400mill")}</span>
                      </a>
                    </h1>
                    <Col md={12}>
                    <div className="">
                      <Form>
                        <Row className="align-items-center">
                          <Col sm={4} className="my-1">
                            <InputGroup className="loc" style={{zIndex:999}}>
                              <InputGroup.Text>
                                {" "}
                                <FontAwesomeIcon icon={faLocationDot}  />{" "}
                              </InputGroup.Text>
                              <Dropdown title={locationlabel} menuStyle={{width:"200px"}} size="lg"> 
                              {groupedOptions &&
                                  groupedOptions?.map((fields) => {
                                    return <Dropdown.Menu title={fields.label} >
                                      {(fields.options).map((row)=>{
                                        return <Dropdown.Item onSelect={(e) =>
                                          handleLocationId(row)
                                        }>{row.label}</Dropdown.Item> 
                                      })} 
                                </Dropdown.Menu>
                                  })
                                }
                                  {/* <Dropdown.Item>Database</Dropdown.Item> 
                                  <Dropdown.Menu title="DSA"> 
                                      <Dropdown.Item disabled>C++</Dropdown.Item> 
                                      <Dropdown.Item>Java</Dropdown.Item> 
                                  </Dropdown.Menu> 
                                  <Dropdown.Item >Blockchain Technology</Dropdown.Item> 
                                  <Dropdown.Menu title="Web Technology"> 
                                      <Dropdown.Menu title="React" disabled> 
                                          <Dropdown.Item >Material UI</Dropdown.Item> 
                                          <Dropdown.Item>React Suite</Dropdown.Item> 
                                      </Dropdown.Menu> 
                                      <Dropdown.Item>HTML</Dropdown.Item> 
                                  </Dropdown.Menu>  */}
                    
                              </Dropdown> 
                              {/* <Select options={groupedOptions} className={"react_select_class"} styles={customStyles}/> */}
                              {/* <Form.Control
                                as="select"
                                onChange={(e) =>
                                  handleLocationId(e.target.value)
                                }
                                value={locationID}
                                name="location"
                                placeholder="location"
                              >
                                <option>{t("location")}</option>
                                {location &&
                                  location?.map((fields) => {
                                    return (
                                      <option value={fields.id}>
                                        {arabicStatus
                                          ? fields.arabic_name
                                          : fields.name}
                                      </option>
                                    );
                                  })}
                              </Form.Control> */}
                            </InputGroup>
                          </Col>

                          <Col sm={8} className="my-1">
                            <InputGroup>
                              <Form.Control
                                id="inlineFormInputGroupUsername"
                                placeholder={t("search")}
                                onChange={handleQuery}
                                onKeyDown={handleKeyDown}
                              />
                              <InputGroup.Text className="search-bg">
                                <FontAwesomeIcon icon="faHippo" />{" "}
                                <FontAwesomeIcon
                                  icon={faMagnifyingGlass}
                                  onClick={handleSearch}
                                />{" "}
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    </Col>
                  </div>
                </Col>

                <Col md={3} className="download">
                  <button>
                    {t("download")}
                    <FontAwesomeIcon className="phone" icon={faMobile} />
                  </button>
                </Col>
              </Row>
            </Container>
          </div>

          <section className="categories_section">
            <Container>
              <Row className="column-reverse">
                <Col lg={9} md={12} sm={12}>
                  <div className="category box d-flex">
                    {categories &&
                      categories?.data?.map((ele, index) => {
                        let colorClass;
                        if (index % 3 == 0) {
                          colorClass = "list-color-bg gradeint-color after-bg";
                        } else if (index % 2 == 0) {
                          colorClass =
                            "list-color-bg gradeint-color-2 after-bg";
                        } else {
                          colorClass =
                            "list-color-bg gradeint-color-3 after-bg";
                        }

                        if (
                          ele.soon_categories == 2 &&
                          ele.popular_categories != 0
                        )
                          return (
                            <Col className="gap" lg={3} md={3} sm={3}>
                              <div
                                className={colorClass}
                                style={{
                                  backgroundImage: `url(${
                                    API_BASE + ele.image
                                  })`,
                                }}
                                onClick={() => categoryBusiness(ele)}
                              >
                                <h4>
                                  {arabicStatus ? ele.arabic_name : ele.name}
                                </h4>
                                <p>
                                  {t("discover")}
                                  {arabicStatus ? (
                                    <FontAwesomeIcon
                                      className="book-icon-1"
                                      icon={faAnglesLeft}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="book-icon-1"
                                      icon={faAnglesRight}
                                    />
                                  )}
                                </p>
                                <img
                                  className="image-list"
                                  src={
                                    ele.image != null
                                      ? API_BASE + ele.image
                                      : noimages
                                  }
                                />
                              </div>
                            </Col>
                          );
                      })}
                  </div>
                </Col>

                <Col lg={3} md={12} sm={12}>
                  <div className="card-ads">
                    <div className="ad-1">
                      <h3>{t("what_business")}</h3>
                      <p>{t("business_content")}</p>
                      <button
                        type="button"
                        onClick={() => {
                          if (!isAuth) setotpShow(!otpShow);
                        }}
                      >
                        {t("free_listing")}
                      </button>
                    </div>
                    <div className="ad-2">
                      <p>
                        {t("connect")} <span>{t("1mill")}</span>
                        <br /> {t("on_sa_finder")}
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          if (!isAuth) setotpShow(!otpShow);
                        }}
                      >
                        {t("business_list")}
                        {/* <b className="free">{t("free")}</b> */}
                      </button>
                    </div>
                    <div className="ad-3">
                      <img src={advertisement} />
                    </div>
                  </div>
                </Col>

                {/* <div className="soon">
                  <h3>{t("list_soon")} </h3>
                  <Col md={12}>
                    <div className="category d-flex">
                      {categories &&
                        categories?.data?.map((ele) => {
                          if (ele.soon_categories != 2)
                            return (
                              <div
                                className="list cursor_pointer"
                                onClick={() => categoryBusiness(ele)}
                              >
                                <div className="cat_img">
                                  <img
                                    className="image-list"
                                    src={
                                      ele.image != null
                                        ? API_BASE + ele.image
                                        : noimages
                                    }
                                  />
                                </div>
                                <div className="cat_info">
                                  <h3>
                                    {arabicStatus ? ele.arabic_name : ele.name}
                                  </h3>
                                </div>
                              </div>
                            );
                        })}
                    </div>
                  </Col>
                </div> */}
              </Row>
            </Container>
          </section>
          <section className="service-list-sec">
            <Container>
              <Row>
                <h3>{t("whats_treding")}</h3>

                <Carousel>
                  <Carousel.Item>
                    <Row>
                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={rest} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "مطاعم" : "Restaurants"}</h3>
                          <p>
                            {arabicStatus
                              ? "أفضل أماكن تناول الطعام مع تجارب الطهي المتنوعة"
                              : "Top dining spots with diverse culinary experiences."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={hot} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "الفنادق" : "Hotels"}</h3>
                          <p>
                            {arabicStatus
                              ? "أماكن إقامة مريحة مع وسائل الراحة والخدمات"
                              : "Comfortable accommodations with amenities and service."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={cafe} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "مقهى" : "Coffee Shop"}</h3>
                          <p>
                            {arabicStatus
                              ? "المقاهي الشعبية التي توفر أماكن مريحة للاستراحة"
                              : "Popular coffee shops offering cozy hangout spots."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={lounge} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "صالة" : "Lounge"}</h3>
                          <p>
                            {arabicStatus
                              ? "أماكن مريحة تقدم المشروبات والموسيقى والأجواء"
                              : "Relaxing venues offering drinks, music, and ambiance."}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Item>

                  <Carousel.Item>
                    <Row>
                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={beauty} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus ? "الجمال والسبا" : "Beauty & Spa"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "علاجات تجديد للاسترخاء والعلاج"
                              : "Rejuvenating treatments for relaxation and therapy."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={hosp} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "مستشفى" : "Hospital"}</h3>
                          <p>
                            {arabicStatus
                              ? "مرافق الرعاية الصحية الرائدة في مجال الرعاية الطبية"
                              : "Leading healthcare facilities for medical care."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={gym} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "نادي رياضي" : "Gym"}</h3>
                          <p>
                            {arabicStatus
                              ? "أفضل مراكز اللياقة البدنية للصحة والتمارين"
                              : "Best fitness centers for health and workouts."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={fun} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus
                              ? "الأنشطة الترفيهية"
                              : "Fun activities"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "خيارات ترفيهية مثيرة للترفيه والمغامرة"
                              : "Exciting leisure options for entertainment and adventure."}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Item>
                </Carousel>
              </Row>
            </Container>
          </section>
          <section className="service-list-sec">
            <Container>
              <Row>
                <h3> {t("last_min")} </h3>
                <Carousel>
                  <Carousel.Item>
                    <Row>
                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={flights} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "الرحلات الجوية" : "Flights"}</h3>
                          <p>
                            {arabicStatus
                              ? "تذكرة طيران مخفضة لخطط السفر العفوية"
                              : "Discounted airfare for spontaneous travel plans."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={concerts} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "حفلات" : "Concerts"}</h3>
                          <p>
                            {arabicStatus
                              ? "تذاكر العروض القادمة بأسعار مخفضة"
                              : "Tickets to upcoming shows at reduced prices."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={car} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus ? "تأجير السيارات" : "Car Rentals"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "تتوفر خيارات تأجير سيارات بأسعار معقولة في اللحظة الأخيرة"
                              : "Affordable last-minute car hire options available."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={tour} />
                        </div>
                        <div className="carousel-content">
                          <h3>{arabicStatus ? "رحلة" : "Tour"}</h3>
                          <p>
                            {arabicStatus
                              ? "جولات إرشادية مخفضة للمغامرات السريعة"
                              : "Discounted guided tours for quick adventures."}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Row>
                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={park} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus
                              ? "متنزه، مدينة ترفيهية"
                              : "Theme Park"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "أسعار دخول مخفضة لأيام المرح الفورية"
                              : "Reduced entry prices for immediate fun days."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={adventure} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus
                              ? "رياضات المغامرة"
                              : "Adventure Sports"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "أنشطة مثيرة بأسعار مخفضة، احجز الآن"
                              : "Thrilling activities at reduced prices, book now."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={theatre} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus ? "عروض مسرحية" : "Theatre Shows"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "أسعار مخفضة للعروض المسرحية الليلة"
                              : "Reduced prices for tonight's theater performances."}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="carousel-img">
                          <img src={shopping} />
                        </div>
                        <div className="carousel-content">
                          <h3>
                            {arabicStatus ? "عروض التسوق" : "Shopping Deals"}
                          </h3>
                          <p>
                            {arabicStatus
                              ? "مبيعات فلاش على العناصر الشعبية، التصرف بسرعة"
                              : "Flash sales on popular items, act fast."}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Item>
                </Carousel>
              </Row>
            </Container>
          </section>
          <Footer></Footer>
        </div>
      </div>
      {/* Businss Send OTP */}
      <Modal show={otpShow} onHide={() => setotpShow(!otpShow)}>
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        setotpShow(!otpShow);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                  <h4 className="title">{t("get_otp")}</h4>
                  <Row>
                    <Col lg={12}>
                      <div class="form_group">
                        <label className="label-style">{t("email")}:</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus ? errors?.email?.ar : errors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button type="submit" class="main-btn">
                        {t("submit")}
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* Business Login By OTP */}
      <Modal show={loginShow} onHide={() => setloginShow(!loginShow)}>
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button className="btn btn-warning">
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={() => setloginShow(!loginShow)}
                      />
                    </button>
                  </div>
                  <h4 className="title">{t("login_info")}</h4>
                  <Row>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("email")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={email}
                          onChange={handleUpdate}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? newerrors?.email?.ar
                            : newerrors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("otp")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("otp")}
                          name="otp"
                          value={newvalues.otp}
                          onChange={handleUpdate}
                        />
                        <p className="text-danger">
                          {" "}
                          {arabicStatus
                            ? newerrors?.otp?.ar
                            : newerrors.otp?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button class="main-btn">{t("login")}</button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* User Send OTP */}
      <Modal
        show={userRegisterShow}
        onHide={() => setuserRegisterShow(!userRegisterShow)}
      >
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleuserRegisterSubmit();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        setuserRegisterShow(!userRegisterShow);
                        console.log(userRegisterShow, 45456890);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                  <h4 className="title">Get OTP</h4>
                  <Row>
                    <Col lg={12}>
                      <div class="form_group">
                        <label className="label-style">Email:</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder="Your Email"
                          name="email"
                          value={userregistervalues.email}
                          onChange={handleuserRegisterFields}
                        />
                        <p className="text-danger">
                          {userregistererrors.email}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button
                        type="submit"
                        class="main-btn"
                        onClick={() => {
                          console.log("object1");
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* User Login By OTP */}
      <Modal
        show={userLoginShow}
        onHide={() => setuserLoginShow(!userLoginShow)}
      >
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleuserLogin();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button className="btn btn-warning">
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={() => setuserLoginShow(!userLoginShow)}
                      />
                    </button>
                  </div>
                  <h4 className="title">{t("login_info")}</h4>
                  <Row>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">Email:</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder="Your Email"
                          name="email"
                          value={user_email}
                          onChange={userLoginFileds}
                        />
                        <p className="text-danger">{userLoginerrors.email}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">OTP:</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder="Your OTP"
                          name="otp"
                          value={userLoginvalues.otp}
                          onChange={userLoginFileds}
                        />
                        <p className="text-danger">{userLoginerrors.otp}</p>
                      </div>
                    </Col>
                    <div class="button">
                      <button class="main-btn">{t("login")}</button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Home;
