import Swal from "sweetalert2";

export function authlogout(payload) {
  Swal.fire({
    title: payload?.arabicStatus ? "خطأ في تسجيل الدخول" : "Login Error",
    text: payload?.arabicStatus
      ? "من فضلك سجل دخولك للمتابعة"
      : "Please SignIn to Continue",
    icon: "error",
  }).then(function () {
    localStorage.clear();
    window.location.reload();
  });
}

// module.exports = {
//     authlogout
// };
