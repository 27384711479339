import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postAllDetails=(postData)=>{
    console.log(postData,34578348)
    const baseUrl=getBaseEndpointUrl();
    return Http.post(baseUrl+ "/user/details_update", postData, Http.getAuthFileToken())
}
export const receiveAllDetails=(query)=>{
    const baseUrl=getBaseEndpointUrl();
    return Http.get(baseUrl+"/user/details_by_id/?query="+query, Http.getAuthToken())
}
export const verifyUserMobiles=(postData)=>{
    const baseUrl=getBaseEndpointUrl();
    console.log(Http.getAuthToken(),"Authtoken")
    // return false;
    return Http.post(baseUrl+"/user/verify_by_otp/",postData, Http.getAuthToken())
}
export const verifyUserOTPMobiles=(postData)=>{
    const baseUrl=getBaseEndpointUrl();
    console.log(Http.getAuthToken(),"Authtoken")
    // return false;
    return Http.post(baseUrl+"/user/verify_by_phoneotp/",postData, Http.getAuthToken())
}