import { action } from "typesafe-actions";
import { LocationActionTypes ,CountryActionTypes, PlaceActionTypes, subLocationActionTypes} from "./setting.model";

  export const settingLocationRequest = (req) =>
    action(LocationActionTypes.LOCATION_LIST_REQUEST, req);
  export const settingLocationSuccess = (res) =>
    action(LocationActionTypes.LOCATION_LIST_SUCCESS, res);
  export const settingLocationFailure = (err) =>
    action(LocationActionTypes.LOCATION_LIST_FAILURE, err);

  export const settingCountryRequest = (req) =>
    action(CountryActionTypes.COUNTRY_LIST_REQUEST, req);
  export const settingCountrySuccess = (res) =>
    action(CountryActionTypes.COUNTRY_LIST_SUCCESS, res);
  export const settingCountryFailure = (err) =>
    action(CountryActionTypes.COUNTRY_LIST_FAILURE, err);
   
  export const settingPlaceRequest = (req) =>
    action(PlaceActionTypes.PLACE_LIST_REQUEST, req);
  export const settingPlaceSuccess = (res) =>
    action(PlaceActionTypes.PLACE_LIST_SUCCESS, res);
  export const settingPlaceFailure = (err) =>
    action(PlaceActionTypes.PLACE_LIST_FAILURE, err);

  export const subLocationRequest = (req) => 
  action(subLocationActionTypes.SUB_LOCATION_LIST_REQUEST, req);
  export const subLocationSuccess = (res) =>
  action(subLocationActionTypes.SUB_LOCATION_LIST_SUCCESS, res);
  export const subLocationFailure = (err) =>
  action(subLocationActionTypes.SUB_LOCATION_LIST_FAILURE, err);

  