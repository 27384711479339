import Swal from "sweetalert2";
import { call, put, takeEvery } from "redux-saga/effects";
import { AuthActionTypes } from "./auth.model";
import { signin, register_by_number } from "./auth.api";
import * as AuthActions from "./auth.action";
import { secretKey } from "../../services/config";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";


export function* handleLogin(action) {
  try {
    const response = yield call(signin, action.payload);
    yield put(AuthActions.loginUserSuccess(response));
  } catch (error) {
    yield put(AuthActions.loginUserFailure(error))
    Swal.fire({
      title:  action?.payload?.arabicStatus ? "تم رفض تسجيل الدخول!" : "Login Denied!",
      text:  action?.payload?.arabicStatus ? "حدث خطأ أثناء محاولة تسجيل الدخول. يرجى المحاولة مرة أخرى لاحقًا." : "An error occurred while attempting to log you in. Please try again later.",
      icon: "error",
    });
  }
}

export function* handleOTP(action) {
  try {
    const response = yield call(register_by_number, action.payload);
    yield put(AuthActions.loginByMobileSuccess(response));
    Swal.fire({
      title:  action?.payload?.arabicStatus ? "أرسل OTP" : "OTP Send",
      text:  action?.payload?.arabicStatus  ? "تم إرسال OTP إلى معرف البريد الإلكتروني المحدد بنجاح" :"OTP send to given Email Id Successfully",
      icon: "success",
    });
  } catch (error) {
    yield put(AuthActions.loginByMobileFailure(error))
    console.log(error);
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthActionTypes.LOGIN_USER_REQUEST, handleLogin);
  yield takeEvery(AuthActionTypes.LOGIN_BY_MOBILE_REQUEST, handleOTP);
}
