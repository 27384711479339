import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";


export const register_by_number = (payload) => {
  console.log(payload, "register_by_number");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/business_user/register_by_number/", payload);
};

export const signin = (payload) => {
  console.log(payload, "LoginPayAPI");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/business_user/login_by_otp", payload);
};
