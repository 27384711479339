import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const register_business_details = (payload) => {
 
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "register_business_details");
  return Http.post(
    baseUrl + "/business_user/user_details",
    payload,
    Http.getAuthFileToken()
  );
};