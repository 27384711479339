import {
  faLinkedin,
  faSquareFacebook,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleRight,
  faAnglesRight,
  faHippo,
  faLocationDot,
  faMagnifyingGlass,
  faMobile,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCaretDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/** Internal Imports */
import { API_BASE } from "../../services/config";

/** API Calls (action) */
import {
  categoryListRequest,
  subcategoryListRequest,
} from "../../store/category/category.action";
/** Selectors */
import { categorySelector } from "../../store/category/category.model";
import noimages from "../../assets/images/shopping.jpg";
import app from "../../assets/images/app_icon.png";
import { settingLocationRequest, subLocationRequest } from "../../store/Setting/setting.action";
import { settingSelector } from "../../store/Setting/setting.model";
function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  let arabicStatus = i18n.language == "ar";

  /** Selectors */
  const { categories, subcaterory } = useSelector(categorySelector);
  const { location, sub_location } = useSelector(settingSelector);
  /** useEffect */
  useEffect(() => {
    dispatch(settingLocationRequest({}));
    dispatch(subLocationRequest({}));
  },[])
  return (
    <>
      <section className="footer-sec">
        <Container>
          <Col Col md={12} lg={12}>
            <h6 className="cursorStyle">{t("popular_catg")}</h6>
            <div className="col-border d-flex ">
              {categories &&
                categories?.data?.map((ele, index) => {
                  if (ele.soon_categories != 1 && ele.popular_categories == 1)
                    return (
                        <ul>
                          <li>
                            <a href="#">
                              {i18n.language == "ar"
                                ? ele.arabic_name
                                : ele.name}
                            </a>
                          </li>
                        </ul>
                    );
                })}
            </div>
          </Col>
          <Col md={12} lg={12}>
            <div className="col-border">
              <h6 className=" cursorStyle">{t("corporate")}</h6>
              <ul>
                <li>
                  <a href="#">{t("about_us")}</a>
                </li>
                <li>
                  <a href="#">{t("contact_us")}</a>
                </li>
                <li>
                  <a href="#">{t("careers")}</a>
                </li>
                <li>
                  <a href="#">{t("media")}</a>
                </li>
                <li>
                  <a href="#">{t("ads")}</a>
                </li>
                <li>
                  <a href="#">{t("adv")}</a>
                </li>
                <li>
                  <a href="#">{t("t&c")}</a>
                </li>
                <li>
                  <a href="#">{t("privacy")}</a>
                </li>
                <li>
                  <a href="#">{t("copyright")}</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={12} lg={12}>
            <div className="col-border">
              <h6 className=" cursorStyle">{t("top_cities")}</h6>
              <ul>
              {location &&
                  location?.map((fields) => {
                    return (
                      <li>
                        <a href="#">
                        {arabicStatus
                          ? fields.arabic_name
                          : fields.name}
                          </a>
                      </li>

                    );
                  })}
              </ul>
            </div>
          </Col>
        </Container>
      </section>
      <section className="footer-sec">
        <Container>
          <div className="footer-logo-card">
            <Row>
              <Col lg={2} md={2}>
                <div>
                  <img src={app} alt="" height="60px" className="cursorStyle" />
                  {/* <h1>JustCall</h1> */}
                </div>
              </Col>
              <Col lg={5} md={5}></Col>
              <Col className="text-align-center" lg={5} md={5}>
                <div className="footer-li">
                  <ul className="ul-align-space">
                    <li>
                      <a>{t("follow")}</a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon
                          className="social-media-icon"
                          icon={faSquareFacebook}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon
                          className="social-media-icon"
                          icon={faSquareInstagram}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon
                          className="social-media-icon"
                          icon={faSquareXTwitter}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FontAwesomeIcon
                          className="social-media-icon"
                          icon={faLinkedin}
                        />
                      </a>
                    </li>
                    <li>
                      <Button>
                        {t("contact_us")}
                        <FontAwesomeIcon
                          className="contact-btn-icon"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div>
            <Row>
              <Col md={6} lg={6}>
                <div className="fm">
                  <ul>
                    <li>
                      <a href="#">{t("about_us")}</a>
                    </li>
                    <li>
                      <a href="#">{t("contact_us")}</a>
                    </li>
                    <li>
                      <a href="#">{t("privacy")}</a>
                    </li>
                    <li>
                      <a href="#">{t("t&c")}</a>
                    </li>
                    <li>
                      <a href="#">{t("help")}</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={6}>
                <div className="text-margin-auto">                
                </div>
              </Col>
            </Row>
          </div>
          <hr className="hr-margin" />
          <Col md={12} lg={12}>
            <div className="copyrights">
              <p>{t("copyright_footer")}</p>
            </div>
          </Col>
        </Container>
      </section>
    </>
  );
}
export default Footer;
