import { Col, Row, Form, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
/** Internal Imports */
import { API_BASE } from "../../services/config";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import {
  deleteAmentiesRequest,
  getAmentiesRequest,
  postAmentiesRequest,
  putAmentiesRequest,
} from "../../store/bussiness/bussiness.action";

function Amenties() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  /* Selectors */
  const { getAmentiesData, amentisSuccess, updateAmentisLoading } =
    useSelector(bussnessCatSelector);

  /* State */
  const [row, setRow] = useState([
    {
      amenities_name: "",
      image_url: "",
      language_type: "",
    },
  ]);
  const [langSwitch, setLangSwitch] = useState(1);
  const [editingIndex, setEditingIndex] = useState(null);

  /* Function */

  const handlesubmit = () => {
    console.log(row[0].amenities_name, 23423)
    if (row[0].amenities_name != "") {
      if (editingIndex !== null) {
        const editedRow = getAmentiesData[editingIndex];
        editedRow.arabicStatus = arabicStatus
        dispatch(putAmentiesRequest({ editedRow, row }));
        setEditingIndex(null);
      } else {
        row.arabicStatus = arabicStatus
        dispatch(postAmentiesRequest(row));
      }
    } else {
      Swal.fire({
        title: arabicStatus ? "حفظ البيانات" : "No Data to Save",
        text: arabicStatus ? "لا توجد بيانات للحفظ" : "No Data to Save",
        icon: "error",
      });
    }

    setRow([
      {
        amenities_name: "",
        image_url: "",
        language_type: "",
      },
    ]);
  };

  const addRow = () => {
    setRow((prevRows) => [
      ...prevRows,
      {
        amenities_name: "",
        image_url: "",
        language_type: "",
      },
    ]);

    setEditingIndex(null);
  };

  const updateRow = (index, field, value) => {
    console.log(value, 4843);
    setRow((prevRows) =>
      prevRows.map((row, i) =>
        i === index
          ? { ...row, [field]: value, language_type: langSwitch }
          : row
      )
    );
  };

  const handleDeleteRow = (index) => {
    let formData = {}
    formData.id = getAmentiesData[index].id;
    formData.arabicStatus = arabicStatus
    dispatch(deleteAmentiesRequest(formData));
  };

  const handleEdit = (index) => {
    const editData = getAmentiesData[index];
    setRow([
      {
        amenities_name:
          editData.amenities_name == null
            ? editData.arabic_amenities_name
            : editData.amenities_name,
        image_url: editData.image_url,
      },
    ]);
    setEditingIndex(index);
  };

  /* UseEffect */
  useEffect(() => {
    if (amentisSuccess || updateAmentisLoading) {
      dispatch(getAmentiesRequest(id));
    }
  }, [id, amentisSuccess, updateAmentisLoading]);

  return (
    <div>
      <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
        <Row>
          <Col md={6}>
            {" "}
            <h4 className="title">
              {editingIndex !== null ? t("amenities") + " " + t("update") : t("amenities") + " " + t("add")}
            </h4>
          </Col>
          <Col md={6}>
            <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
              {langSwitch == 1 ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setLangSwitch(2)}
                >
                  اﻟﻌﺮﺑﻴﺔ
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setLangSwitch(1)}
                >
                  English
                </button>
              )}
            </div>
          </Col>
        </Row>

        <div className="amenties">
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={addRow}
          >
            {t("add")}
          </button>
        </div>

        {row &&
          row?.map((amenitiData, index) => {
            return (
              <Row>
                <Col lg={6} key={index}>
                  <div className="form_group">
                    <label className="label-style">{t("amenities") + " " + t("name")}:</label>
                    <input
                      type="text"
                      className="form_control"
                      placeholder={t("amenities") + " " + t("name")}
                      value={amenitiData?.amenities_name}
                      onChange={(e) => {
                        updateRow(index, "amenities_name", e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6} key={index}>
                  <div className="filled form-group tooltip-end-top">
                    <label className="label-style">{t("amenities") + " " + t("logo")}:</label>
                    <Form.Control
                      className="border-business"
                      type="file"
                      accept="image/*"
                      name="image_url"
                      onChange={(e) => {
                        updateRow(index, "image_url", e.target.files[0]);
                      }}
                      required
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        <Col lg={12}>
          <div className="button d-flex justify-content-end">
            <button className="main-btn " type="button" onClick={handlesubmit}>
              {editingIndex !== null ? t("amenities") + " " + t("update") : t("amenities") + " " + t("add")}
            </button>
          </div>
        </Col>
      </div>
      <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
        <h4 className="title">{t("amenities") + " " + t("list")}</h4>

        <Row>
          <Col lg={12}>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t("s_no")}</th>
                  <th>{t("amenities") + " " + t("name")}</th>
                  <th>{t("images") + " " + t("preview")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {getAmentiesData?.map((ele, ind) => (
                  <tr key={ele.id}>
                    <th scope="row">{ind + 1}</th>
                    <td>
                      {ele.amenities_name == null
                        ? ele.arabic_amenities_name
                        : ele.amenities_name}
                    </td>
                    <td>
                      <img
                        style={{ height: "50px" }}
                        src={API_BASE + ele.image_url}
                        alt=""
                      />
                    </td>
                    <td>
                      <button
                        className="main-btn mr-5"
                        onClick={() => handleEdit(ind)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="main-btn"
                        onClick={() => handleDeleteRow(ind)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Amenties;
