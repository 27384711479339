import { all } from "redux-saga/effects";
import { CategorySaga } from "./category/category.saga";
import { AuthSaga } from "./business_user/auth.saga";
import { BusinessSaga } from "./business_user/business_user_details.saga";
import { SettingSaga } from "./Setting/setting.saga";
import { BussinessCatSaga } from "./bussiness/bussiness.saga";
import { UserSaga } from "./user/user.saga";
import { UserDetailSaga } from "./UserDetails/userdetail.saga";

export default function* rootSaga() {
  yield all([
    CategorySaga(),
    AuthSaga(),
    SettingSaga(),
    BusinessSaga(),
    BussinessCatSaga(),
    UserSaga(),
    UserDetailSaga()
  ]);
}
