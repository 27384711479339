import { all, call, put, takeEvery } from "redux-saga/effects";
import { CategoryActionTypes } from "./category.model";
import * as CategoryActions from "./category.action";
import { getCategory, getSubCategory } from "./category.api";

export function* handleUpdateUser() {
  try {

    const response = yield call(getCategory);
    yield put(CategoryActions.categoryListSuccess(response));
    console.log(response, "CategoryResponse");
  } catch (e) {
    yield put(CategoryActions.categoryListFailure(e));
  }
}

export function* handlegetSubCat(){
  try {
    const response=yield call(getSubCategory);
    yield put(CategoryActions.subcategoryListSuccess(response))
    
  } catch (e) {
    yield put(CategoryActions.subcategoryListFailure(e)) 
  }
}

export function* CategorySaga() {
  yield takeEvery(CategoryActionTypes.CATEGORY_LIST_REQUEST, handleUpdateUser);
  yield takeEvery(CategoryActionTypes.SUBCATEGORY_LIST_REQUEST, handlegetSubCat)
}
