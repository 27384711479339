const localHost = "localhost";
export const API_BASE =
  process.env.REACT_APP_NODE_ENV === "uat"
    ? process.env.REACT_APP_NODEUATASSETURL
    : process.env.REACT_APP_NODELOCALASSETUR7 || "http://localhost:4007/";
const localhostEndpoint = `${API_BASE}api/v1`;
const localHostBaseEndpoint =
  process.env.REACT_APP_NODE_ENV === "uat"
    ? process.env.REACT_APP_NODEUATURL
    : process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_NODELOCALURL
    : localhostEndpoint;

export const assestURL =
  process.env.REACT_APP_NODE_ENV === "uat"
    ? process.env.REACT_APP_NODEUATASSETURL
    : process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_NODELOCALASSETURL
    : process.env.REACT_APP_NODEPRODASSETURL;
export const secretKey = process.env.REACT_APP_SECRETKEY
  ? process.env.REACT_APP_SECRETKEY
  : "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7";
export const DateFormat = process.env.REACT_APP_DATEFORMAT
  ? process.env.REACT_APP_DATEFORMAT
  : "MM/DD/YYYY";
export const DateTimeFormat = process.env.REACT_APP_DATETIMEFORMAT
  ? process.env.REACT_APP_DATETIMEFORMAT
  : "MM/DD/YYYY hh:mm:ss A";

export const getBaseEndpointUrl = () => {
  return localHostBaseEndpoint;
};

function getHostURL(hostName) {
  if (hostName.includes(localHost)) {
    return localHostBaseEndpoint;
  }
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
