import { action } from "typesafe-actions";
import { BussinessActionType } from "./bussiness.model";

export const bussCatListRequest = (req) =>
  action(BussinessActionType.BUSSNESSCAT_LIST_REQUEST, req);
export const bussCatListSuccess = (res) =>
  action(BussinessActionType.BUSSNESSCAT_LIST_SUCCESS, res);
export const bussCatListFailure = (err) =>
  action(BussinessActionType.BUSSNESSCAT_LIST_FAILURE, err);

export const linksRequest = (req) =>
  action(BussinessActionType.LINKS_LIST_REQUEST, req);
export const linksScucess = (res) =>
  action(BussinessActionType.LINKS_LIST_SUCCESS, res);
export const linksFailure = (err) =>
  action(BussinessActionType.LINKS_LIST_FAILURE, err);

export const linkGetRequest = (req) =>
  action(BussinessActionType.LINKSGET_LIST_REQUEST, req);
export const linkGetSuccess = (res) =>
  action(BussinessActionType.LINKSGET_LIST_SUCCESS, res);
export const lilkGetFailure = (err) =>
  action(BussinessActionType.LINKSGET_LIST_FAILURE, err);

export const detailPageDataRequest = (req) =>
  action(BussinessActionType.DETAILPAGEDATA_REQUEST, req);
export const detailPageDataSuccess = (res) =>
  action(BussinessActionType.DETAILPAGEDATA_SUCCESS, res);
export const detailPageDataFailure = (err) =>
  action(BussinessActionType.DETAILPAGEDATA_FAILURE, err);

export const imageSendRequest = (req) =>
  action(BussinessActionType.IMAGE_LIST_REQUEST, req);
export const imageSendSuccess = (res) =>
  action(BussinessActionType.IMAGE_LIST_SUCCESS, res);
export const imageSendFailure = (err) =>
  action(BussinessActionType.IMAGE_LIST_FAILURE, err);

export const timeSendRequest = (req) =>
  action(BussinessActionType.WORKTYM_LIST_REQUEST, req);
export const timeSendSuccess = (res) =>
  action(BussinessActionType.WORKTYM_LIST_SUCCESS, res);
export const timeSendFailure = (err) =>
  action(BussinessActionType.WORKTYM_LIST_FAILURE, err);

export const reviewListRequest = (req) =>
  action(BussinessActionType.REVIEW_LIST_REQUEST, req);
export const reviewListSuccess = (res) =>
  action(BussinessActionType.REVIEW_LIST_SUCCESS, res);
export const reviewListFailure = (err) =>
  action(BussinessActionType.REVIEW_LIST_FAILURE, err);

export const postreviewListRequest = (req) =>
  action(BussinessActionType.POSTREVIEW_LIST_REQUEST, req);
export const postreviewListSuccess = (res) =>
  action(BussinessActionType.POSTREVIEW_LIST_SUCCESS, res);
export const postreviewListFailure = (err) =>
  action(BussinessActionType.POSTREVIEW_LIST_FAILURE, err);

export const postMenuListRequest = (req) =>
  action(BussinessActionType.POSTMENU_LIST_REQUEST, req);
export const postMenuListSuccess = (res) =>
  action(BussinessActionType.POSTMENU_LIST_SUCCESS, res);
export const postMenuListFailure = (err) =>
  action(BussinessActionType.POSTMENU_LIST_FAILURE, err);

export const getMenuListRequest = (req) =>
  action(BussinessActionType.GETMENU_LIST_REQUEST, req);
export const getMenuListSuccess = (res) =>
  action(BussinessActionType.GETMENU_LIST_SUCCESS, res);
export const getMenuListFailure = (err) =>
  action(BussinessActionType.LINKSGET_LIST_FAILURE, err);

export const postEnquiryListRequest = (req) =>
  action(BussinessActionType.POSTENQUIRY_LIST_REQUEST, req);
export const postEnquiryListSuccess = (res) =>
  action(BussinessActionType.POSTENQUIRY_LIST_SUCCESS, res);
export const postEnquiryListFailure = (err) =>
  action(BussinessActionType.POSTENQUIRY_LIST_FAILURE, err);

export const searchRequest = (req) =>
  action(BussinessActionType.SEARCH_REQUEST, req);
export const searchSuccess = (res) =>
  action(BussinessActionType.SEARCH_SUCCESS, res);
export const searchFailure = (err) =>
  action(BussinessActionType.SEARCH_FAILURE, err);

export const getRelatedBussinessListRequest = (req) =>
  action(BussinessActionType.GETRELDBUSSINESS_LIST_REQUEST, req);
export const getRelatedBussinessListSuccess = (res) =>
  action(BussinessActionType.GETRELDBUSSINESS_LIST_SUCCESS, res);
export const getRelatedBussinessListFailure = (err) =>
  action(BussinessActionType.GETRELDBUSSINESS_LIST_FAILURE, err);

export const postFavBussinessRequest = (req) =>
  action(BussinessActionType.POSTFAVBUSINESS_REQUEST, req);
export const postFavBussinessSuccess = (res) =>
  action(BussinessActionType.POSTFAVBUSINESS_SUCCESS, res);
export const postFavBussinessFailure = (err) =>
  action(BussinessActionType.POSTFAVBUSINESS_FAILURE, err);

/* Amenties Post  */
export const postAmentiesRequest = (req) =>
  action(BussinessActionType.POSTAMENTIES_REQUEST, req);
export const postAmentiesScucess = (res) =>
  action(BussinessActionType.POSTAMENTIES_SUCCESS, res);
export const postAmentiesFailure = (err) =>
  action(BussinessActionType.POSTAMENTIES_FAILURE, err);

/* Amenties Get */
export const getAmentiesRequest = (req) =>
  action(BussinessActionType.GETAMENTIES_REQUEST, req);
export const getAmentiesScucess = (res) =>
  action(BussinessActionType.GETAMENTIES_SUCCESS, res);
export const getAmentiesFailure = (err) =>
  action(BussinessActionType.GETAMENTIES_FAILURE, err);

/** Reset States */
export const reseDataRequest = (req) =>
  action(BussinessActionType.RESET_REQUEST, req);

export const putAmentiesRequest = (req) =>
  action(BussinessActionType.PUT_AMENTIES_REQUEST, req);
export const putAmentiesScucess = (res) =>
  action(BussinessActionType.PUT_AMENTIES_SUCCESS, res);
export const putAmentiesFailure = (err) =>
  action(BussinessActionType.PUT_AMENTIES_FAILURE, err);

export const deleteAmentiesRequest = (req) =>
  action(BussinessActionType.DELETE_AMENTIES_REQUEST, req);
export const deleteAmentiesScucess = (res) =>
  action(BussinessActionType.DELETE_AMENTIES_SUCCESS, res);
export const deleteAmentiesFailure = (err) =>
  action(BussinessActionType.DELETE_AMENTIES_FAILURE, err);

/* Offer Post & Get  */
export const postOfferDetailRequest = (req) =>
  action(BussinessActionType.OFFERDETAILPOST_REQUEST, req);
export const postOfferDetailScucess = (res) =>
  action(BussinessActionType.OFFERDETAILPOST_SUCCESS, res);
export const postOfferDetailFailure = (err) =>
  action(BussinessActionType.OFFERDETAILPOST_FAILURE, err);

export const GetOfferDetailRequest = (req) =>
  action(BussinessActionType.OFFERDETAILGET_REQUEST, req);
export const GetOfferDetailScucess = (res) =>
  action(BussinessActionType.OFFERDETAILGET_SUCCESS, res);
export const GetOfferDetailFailure = (err) =>
  action(BussinessActionType.OFFERDETAILGET_FAILURE, err);

export const postSpecialDetailRequest = (req) =>
  action(BussinessActionType.SPECIALDEALSPOST_REQUEST, req);
export const postSpecialDetailScucess = (res) =>
  action(BussinessActionType.SPECIALDEALSPOST_SUCCESS, res);
export const postSpecialDetailFailure = (err) =>
  action(BussinessActionType.SPECIALDEALSPOST_FAILURE, err);

export const GetSpecialDetailRequest = (req) =>
  action(BussinessActionType.SPECIALDEALSGET_REQUEST, req);
export const GetSpecialDetailScucess = (res) =>
  action(BussinessActionType.SPECIALDEALSGET_SUCCESS, res);
export const GetSpecialDetailFailure = (err) =>
  action(BussinessActionType.SPECIALDEALSGET_FAILURE, err);

// Deals Post & Get
export const postDealDetailRequest = (req) =>
  action(BussinessActionType.DEALDETAILPOST_REQUEST, req);
export const postDealDetailScucess = (res) =>
  action(BussinessActionType.DEALDETAILPOST_SUCCESS, res);
export const postDealDetailFailure = (err) =>
  action(BussinessActionType.DEALDETAILPOST_FAILURE, err);

export const getDealDetailRequest = (req) =>
  action(BussinessActionType.DEALDETAILGET_REQUEST, req);
export const getDealDetailScucess = (res) =>
  action(BussinessActionType.DEALDETAILGET_SUCCESS, res);
export const getDealDetailFailure = (err) =>
  action(BussinessActionType.DEALDETAILGET_FAILURE, err);
