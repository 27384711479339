export const UserDetailTypes={
    USERDETAIL_SEND_REQUEST: "@@userdetail/USERDETAIL_SEND_REQUEST",
    USERDETAIL_SEND_SUCCESS: "@@userdetail/USERDETAIL_SEND_SUCCESS",
    USERDETAIL_SEND_FAILURE: "@@userdetail/USERDETAIL_SEND_FAILURE",

    GETUSERDETAIL_RECIEVE_REQUEST: "@@getuserdetail/GETUSERDETAIL_RECIEVE__REQUEST",
    GETUSERDETAIL_RECIEVE_SUCCESS: "@@getuserdetail/GETUSERDETAIL_RECIEVE__SUCCESS",
    GETUSERDETAIL_RECIEVE_FAILURE: "@@getuserdetail/GETUSERDETAIL_RECIEVE__FAILURE",

    USER_VERIFYMOBILE_REQUEST: "@@userverify/USER_VERIFYMOBILE_REQUEST",
    USER_VERIFYMOBILE_SUCCESS: "@@userverify/USER_VERIFYMOBILE_SUCCESS",
    USER_VERIFYMOBILE_FAILURE: "@@userverify/USER_VERIFYMOBILE_FAILURE",

    USER_VERIFYMOBILEOTP_REQUEST: "@@userverify/USER_VERIFYMOBILEOTP_REQUEST",
    USER_VERIFYMOBILEOTP_SUCCESS: "@@userverify/USER_VERIFYMOBILEOTP_SUCCESS",
    USER_VERIFYMOBILEOTP_FAILURE: "@@userverify/USER_VERIFYMOBILEOTP_FAILURE",
    
    RESETSTATE_SEND_REQUEST:"@@resetstate/RESETSTATE_SEND_REQUEST"
}
export const userDetailInitialState={
    initLoading:false,
    getinitLoading:false,
    verifyinitLoading:false,
    verifyotpinitLoading:false,
    otpVSucces:false,
    otpSucces:false,
    sendSucces:false,
    getUserData:[]
}
export const userDetailSelector =(state)=>state.userDetailReducer;